import React, { createContext, useContext, useState } from 'react'
import IBSToast from '../components/IBSToast'

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toastProps, setToastProps] = useState({ visible: false });

    const showToast = (message, duration = 1500) => {
        setToastProps({ visible: true, message, duration });
    };

    const hideToast = () => {
        setToastProps({ visible: false });
    };

    return (
        <ToastContext.Provider value={{ showToast, hideToast }}>
            {children}
            <IBSToast {...toastProps} funcSetVisible={hideToast} />
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);