import { createContext, useState } from "react"
import { BuilderTechnology } from "../models/enums"

export const IBSWsSettingsContext = createContext({})

export function IBSWsSettingsProvider({ children }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [endpoint, setEndpoint] = useState('')
    const [baseUrl, setBaseUrl] = useState('')
    const [timeout, setTimeout] = useState(5000)
    const [multiempresa, setMultiempresa] = useState(false)
    const [ibsKey, setIbsKey] = useState('')
    const [sistema, setSistema] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [builderTechnology, setBuilderTechnology] = useState(BuilderTechnology.expoGo)
    const [deviceId, setDeviceId] = useState('')
    const [nmAplicativo, setNmAplicativo] = useState()
    const [idApp, setIdApp] = useState()

    function setWsSettings(settings) {
        if (settings?.endpoint)
            setEndpoint(settings.endpoint)
        if (settings?.url_porta)
            setBaseUrl(settings.url_porta)
        if (settings?.multi_empresa)
            setMultiempresa(settings.multi_empresa)
        if (settings?.ibs_key)
            setIbsKey(settings.ibs_key)
        if (settings?.sistema)
            setSistema(settings.sistema)
        if (settings?.cnpj)
            setCnpj(settings.cnpj)
    }

    return (
        <IBSWsSettingsContext.Provider value={{
            endpoint, setEndpoint,
            baseUrl, setBaseUrl,
            timeout, setTimeout,
            setUsername,
            setPassword,
            basicAuth: { 'username': username, 'password': password },
            multiempresa, setMultiempresa,
            ibsKey, setIbsKey,
            sistema, setSistema,
            cnpj, setCnpj,
            builderTechnology, setBuilderTechnology,
            deviceId, setDeviceId,
            setWsSettings,
            settings: {
                'baseUrl': baseUrl,
                'endpoint': endpoint,
                'timeout': timeout,
                'basicAuth': {
                    'username': username,
                    'password': password
                },
                'multiempresa': multiempresa,
                'ibsKey': ibsKey,
                'sistema': sistema,
                'cnpj': cnpj
            },
            nmAplicativo, setNmAplicativo,
            idApp, setIdApp
        }}>
            {children}
        </IBSWsSettingsContext.Provider>
    )
}