import { View } from 'react-native'
import { getColorEstoque, verifyFirstColumn } from '../../utils/utils'
import { styles } from './styles'
import IBSPressCard from '../../components/IBSPressCard'
import IBSText from '../../components/IBSText'
import IBSSeparator from '../../components/IBSSeparator'
import { colors } from '../../styles/colors'
import IBSIconButton from '../../components/IBSIconButton'

export default function CardProduto(props) {
    const { produto, onPress, index, numColumns, widthCard, mostrarCusto, custoVisible, setCustoVisible } = props

    const style = styles(widthCard)
    const backgroundColor = produto?.produt_tipo == 1 ? colors.ColorServico : getColorEstoque(produto?.estoque, produto?.outras_filiais)

    const firstColumn = verifyFirstColumn(index, numColumns)

    return (
        <IBSPressCard style={[style.container, !firstColumn ? { marginLeft: 10 } : undefined]} onPress={onPress} styleContainer={{ flex: 1 }}>
            <View style={[style.viewTitle, { backgroundColor: backgroundColor }]}>
                <View style={style.viewLeft}>
                    <IBSText children={`ID ${produto?.id}`} numberOfLines={1} fontWeight='bold' style={style.textTitle} />
                </View>
                <View style={style.viewRight}>
                    <IBSText children={`R$ ${produto?.preco?.valor_un?.toFixed(2)}`} fontWeight='bold' style={style.textTitle} />
                </View>
            </View>
            <View style={style.viewPrinc}>
                <View style={[style.viewRow]}>
                    <IBSText children={`${produto?.descricao}`} numberOfLines={3} fontWeight='bold' />
                </View>
                <IBSSeparator style={style.separador} />
                {mostrarCusto &&
                    <View style={[style.viewRow]}>
                        <IBSText children={'Custo: '} fontWeight='bold' />
                        {custoVisible &&
                            <IBSText children={`R$ ${produto?.preco?.custo?.toFixed(2)}`} numberOfLines={1} />
                        }
                        <IBSIconButton
                            transparent={true}
                            icon={custoVisible ? 'eye-off' : 'eye'}
                            iconDirectory='ionicons'
                            size={18}
                            onPress={() => setCustoVisible(!custoVisible)}
                            style={custoVisible ? { marginLeft: 5 } : undefined}
                        />
                    </View>
                }
                {produto?.codigofabrica &&
                    <View style={style.viewRow}>
                        <IBSText children={'Cód. Fábrica: '} fontWeight='bold' />
                        <IBSText children={produto?.codigofabrica} numberOfLines={1} />
                    </View>
                }
                <View style={style.viewRow}>
                    <IBSText children={'Estoque: '} fontWeight='bold' />
                    <IBSText children={produto?.estoque?.toFixed(2)} numberOfLines={1} />
                </View>
                <View style={style.viewRow}>
                    <IBSText children={'Estoque Fil.: '} fontWeight='bold' />
                    <IBSText children={produto?.outras_filiais ? produto?.outras_filiais?.toFixed(2) : '0.00'} numberOfLines={1} />
                </View>
                {produto?.marca &&
                    <View style={style.viewRow}>
                        <IBSText children={'Marca: '} fontWeight='bold' />
                        <IBSText children={produto?.marca} numberOfLines={1} />
                    </View>
                }
            </View>
        </IBSPressCard>
    )
}