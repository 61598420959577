import IBSSplashScreen from "../../../apps-react-native-core/components/IBSSplashScreen"
import { NomeAplicativoEnum } from "../../../apps-react-native-core/models/enums"

export default function SplashScreenView({ navigation }) {
    return <IBSSplashScreen 
        navigationLogin={'Login'} 
        navigation={navigation} 
        navigationHome={"ConsultaProduto"}
        nmAplicativo={NomeAplicativoEnum.ibsConsultaProdutos}
        idApp={3} //IBS Consulta Produtos
    />
}