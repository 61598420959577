import { View } from "react-native";
import IBSText from "../IBSText";
import IBSIconButton from "../IBSIconButton";
import { styles } from "./styles"
import { ThemeContext } from "../../contexts/themeContext";
import { useContext } from "react";

export default function IBSHeader(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    return (
        <View style={style.container}>
            <View style={style.viewLeft}>
                {props?.backButton &&
                    <IBSIconButton
                        icon='arrow-back-ios'
                        iconDirectory='materialIcons'
                        size={22}
                        transparent={true}
                        onPress={() => { props?.backAction ? props?.backAction() : props?.navigation.goBack()}}
                        style={style.buttonBack}
                    />
                }
            </View>
            <View style={style.viewCenter}>
                <IBSText
                    children={props?.headerText}
                    fontWeight={'bold'}
                    style={[style.title, props?.headerText?.length > 20 ? props?.headerText?.length > 24 ? { fontSize: 16 } : { fontSize: 18 } : { fontSize: 20 }]}
                    onPress={() => { props?.backActionOnTitle ? props?.backAction ? props?.backAction() : props?.navigation.goBack() : undefined }}
                />
            </View>
            <View style={style.viewRight}>
                {props?.children}
            </View> 
        </View>
    )
}

/* 

    PROPS

    navigation - Passar o componente de navegação
    headerText - Texto do título da tela
    backButton - true/false - botão de retorno
    children - Caso precisar pode ser inserido um componente filho no Header, por exemplo, ícones no canto direito
    backActionOnTitle - true/false - Se o título for clicável, passar true para que a ação de retorno seja executada

*/