import { StyleSheet } from "react-native"
import { colors } from "../../styles/colors"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        viewContainer: {
            borderRadius: 5,
        },
        inputContainer: {
            color: customTheme.textInput?.textColor ? customTheme.textInput?.textColor : theme.textInput?.textColor,
            fontSize: 15,
            flex: 1,
        },
        inputContainerText: {
            color: customTheme.textInput?.textColor ? customTheme.textInput?.textColor : theme.textInput?.textColor,
            fontSize: 15,
            //flex: 1,
            textAlignVertical: 'center',
        },
        inputFocused: {
            backgroundColor: customTheme.transparency
                ? `rgba(${customTheme.transparency[0]},${customTheme.transparency[1]},${customTheme.transparency[2]},${customTheme.transparency[3] * 2})`
                : `rgba(${theme.transparency[0]},${theme.transparency[1]},${theme.transparency[2]},${theme.transparency[3] * 2})`
        },
        inputUnfocused: {
            backgroundColor: customTheme.transparency
                ? `rgba(${customTheme.transparency[0]},${customTheme.transparency[1]},${customTheme.transparency[2]},${customTheme.transparency[3]})`
                : `rgba(${theme.transparency[0]},${theme.transparency[1]},${theme.transparency[2]},${theme.transparency[3]})`
        },
        labelContainer: {
            position: "absolute",
            flexDirection: 'row',
            top: 7,
        },
        fontLabel: {
            fontSize: 9,
        },
        fontLabelRequired: {
            fontSize: 9,
            color: customTheme.secondaryColor ? customTheme.secondaryColor : theme.secondaryColor,
        },
        icon: {
            backgroundColor: 'transparent',
            position: 'absolute',
            top: 0,
            left: 5,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
        },
        iconDropDown: {
            backgroundColor: 'transparent',
            position: 'absolute',
            top: 0,
            right: 5,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
        },
        iconShowPassword: {
            position: 'absolute',
            top: 0,
            right: 10,
            bottom: 0,
        },
        colorRed: {
            color: colors.FireBrick
        }
    })
}