export function JsonAppLoginModel(deviceId) {
    return {
        "action": "app-login",
        "data": {},
        "device": deviceId
    }
}

export function JsonAppLogoutModel(deviceId) {
    return {
        "action": "app-logout",
        "data": {},
        "device": deviceId
    }
}

export function JsonFilialObterModel(deviceId) {
    return {
        "action": "filial-obter",
        "device": deviceId
    }
}

export function JsonPainelOsAndamentoObterModel(filialId) {
    return {
        "action": "painel-os-andamento-obter",
        "data": {
            "filial_id": filialId
        }
    }
}

export function JsonPainelOsObterModel(filialId, painelId) {
    return {
        "action": "painel-os-obter",
        "data": {
            "filial_id": filialId,
            "painel_id": painelId
        }
    }
}

export function JsonPainelOsDetalhesObter(itePaiId) {
    return {
        "action": "painel-os-detalhes-obter",
        "data": {
            "itepai_id": itePaiId
        }
    }
}

export function JsonMecanicoObter() {
    return {
        "action": "mecanico-obter",
    }
}

export function JsonMedidaPneuObter() {
    return {
        "action": "medida-pneu-obter",
    }
}

export function JsonOsIniciar(itePaiId, MecanicoId, MecanicoSenha, KmAtual, MedPneuId, ObsPainel) {
    return {
        "action": "painel-os-iniciar",
        "data": {
            "itepai_id": itePaiId,
            "mecani_id": MecanicoId,
            "mecani_senha": MecanicoSenha,
            "ordser_km_atual": KmAtual,
            "medpne_id": MedPneuId,
            "ordser_obs_painel": ObsPainel
        }
    }
}

export function JsonOsPausar(itePaiId, MecanicoId, MecanicoSenha, KmAtual, MedPneuId, ObsPainel) {
    return {
        "action": "painel-os-pausar",
        "data": {
            "itepai_id": itePaiId,
            "mecani_id": MecanicoId,
            "mecani_senha": MecanicoSenha,
            "ordser_km_atual": KmAtual,
            "medpne_id": MedPneuId,
            "ordser_obs_painel": ObsPainel
        }
    }
}

export function JsonGetVeiculoModeloModel(deviceId, id, offset, limit) {
    let objData = {}

    if (id)
        objData['veimod_id'] = id
    if (offset)
        objData['offset'] = offset
    if (limit)
        objData['limit'] = limit

    return {
        "action": "get-veiculo-modelo",
        "data": objData,
        "device": deviceId
    }
}

export function JsonGetAroModel(deviceId, id, offset, limit) {
    let objData = {}

    if (id)
        objData['aro_id'] = id
    if (offset)
        objData['offset'] = offset
    if (limit)
        objData['limit'] = limit

    return {
        "action": "get-aro",
        "data": objData,
        "device": deviceId
    }
}

export function JsonGetMedidaPneuModel(deviceId, id, offset, limit) {
    let objData = {}

    if (id)
        objData['id'] = id
    if (offset)
        objData['offset'] = offset
    if (limit)
        objData['limit'] = limit

    return {
        "action": "get-medida-pneu",
        "data": objData,
        "device": deviceId
    }
}

export function JsonGetProdutoModel(deviceId, filialId, tabPrecoId, somenteAtivo = true, somenteComEstoque = false, regEspecifico, offset = 0, limit, subProId, conPagId, eanDun) {
    let objData = {}

    if (filialId)
        objData['filial_id'] = filialId
    if (tabPrecoId)
        objData['tabpre_id'] = tabPrecoId
    if (regEspecifico)
        objData['registro_especifico'] = regEspecifico
    if (subProId)
        objData['subpro_id'] = subProId
    if (eanDun)
        objData['ean_dun'] = eanDun
    if (conPagId)
        objData['conpag_id'] = conPagId
    if (limit)
        objData['limit'] = limit

    objData['somente_ativo'] = somenteAtivo
    objData['somente_com_estoque'] = somenteComEstoque
    objData['offset'] = offset

    return {
        "action": "get-produto",
        "data": objData,
        "device": deviceId
    }
}

export function JsonGetParametrosModel(deviceId, parametroId) {
    return {
        "action": "get-parametro",
        "data": {
            "parame_id": parametroId
        },
        "device": deviceId
    }
}

export function JsonGetVeiculoModel(deviceId, veicId, veicPlaca, offset = 0, limit) {
    return {
        "action": "get-veiculo",
        "data": {
            "veicul_id": veicId,
            "veicul_placa": veicPlaca,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonSetVeiculoModel(deviceId, filialId, objVeiculo) {
    return {
        "action": "set-veiculo",
        "data": {
            "filial_id": filialId,
            "veiculo": objVeiculo
        },
        "device": deviceId
    }
}

export function JsonGetEntidadeModel(deviceId, entidaId, entidaNome, cpfCnpj, offset = 0, limit) {
    return {
        "action": "get-entidade",
        "data": {
            "entida_id": entidaId,
            "entida_nome": entidaNome,
            "cpf_cnpj": cpfCnpj,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonGetEntidadeClienteModel(deviceId, entidaId, entidaNome, cpfCnpj, offset = 0, limit) {
    let json = JsonGetEntidadeModel(deviceId, entidaId, entidaNome, cpfCnpj, offset, limit)
    json.data['entida_cliente'] = 1

    return json
}

export function JsonGetEstadoModel(deviceId, estadoId, offset = 0, limit) {
    return {
        "action": "get-estado",
        "data": {
            "estado_id": estadoId,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonGetMunicipioModel(deviceId, estadoId, municiId, offset = 0, limit, order_by = ["munici_nome"]) {
    return {
        "action": "get-municipio",
        "data": {
            "munici_id": municiId,
            "estado_id": estadoId,
            "offset": offset,
            "limit": limit,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetRegimeTributacaoModel(deviceId, regimeTribId, offset = 0, limit, order_by = ["crt_descricao"]) {
    return {
        "action": "get-regime-tributacao",
        "data": {
            "crt_id": regimeTribId,
            "offset": offset,
            "limit": limit,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetConhecimentoEmpresaModel(deviceId, conEmpId, offset = 0, limit, order_by = ["conemp_descricao"]) {
    return {
        "action": "get-conhecimento-empresa",
        "data": {
            "conemp_id": conEmpId,
            "offset": offset,
            "limit": limit,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetCarteiraClienteModel(deviceId, carCliId, offset = 0, limit, order_by = ["carcli_nome"]) {
    return {
        "action": "get-carteira-cliente",
        "data": {
            "carcli_id": carCliId,
            "offset": offset,
            "limit": limit,

            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetTipoConsumoModel(deviceId, tipConsId, offset = 0, limit, order_by = ["tipcon_descricao"]) {
    return {
        "action": "get-tipo-consumo",
        "data": {
            "tipcon_id": tipConsId,
            "limit": limit,
            "offset": offset,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetTipoEnderecoModel(deviceId, tipEndId, offset = 0, limit, order_by = ["tipend_descricao"]) {
    return {
        "action": "get-tipo-endereco",
        "data": {
            "tipend_id": tipEndId,
            "limit": limit,
            "offset": offset,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetTabelaPrecoModel(deviceId, tabPrecoId, offset = 0, limit, order_by = ["tabpre_descricao"]) {
    return {
        "action": "get-tabela-preco",
        "data": {
            "tabpre_id": tabPrecoId,
            "limit": limit,
            "offset": offset,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonGetContatoModel(deviceId, contatId, entidaId, offset = 0, limit) {
    return {
        "action": "get-contato",
        "data": {
            "contat_id": contatId,
            "entida_id": entidaId,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonGetEnderecoModel(deviceId, endereId, entidaId, offset = 0, limit) {
    return {
        "action": "get-endereco",
        "data": {
            "endere_id": endereId,
            "entida_id": entidaId,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonGetTipoEmailModel(deviceId, tipEmailId, offset = 0, limit, order_by = ["tipema_descricao"]) {
    return {
        "action": "get-tipo-email",
        "data": {
            "tipema_id": tipEmailId,
            "offset": offset,
            "limit": limit,
            "order_by": order_by
        },
        "device": deviceId
    }
}

export function JsonSetEntidadeModel(deviceId, filialId, data) {
    const { entidade, endereco, contato } = data

    return {
        "action": "set-entidade",
        "data": {
            "filial_id": filialId,
            "entidade": entidade,
            "endereco": endereco,
            "contato": contato,
        },
        "device": deviceId
    }
}

export function JsonGetPermissoesModel(deviceId, aplicaId, aplTelId, offset, limit) {
    return {
        "action": "get-permissoes",
        "data": {
            "aplica_id": aplicaId,
            "apltel_id": aplTelId,
            "offset": offset,
            "limit": limit,
        },
        "device": deviceId
    }
}

export function JsonGetHistoricoResumidoModel(deviceId, entidaId, veiculoId, vendedorId, offset = 0, limit) {
    return {
        "action": "get-historico-resumido",
        "data": {
            "entida_id": entidaId,
            "veicul_id": veiculoId,
            "entven_id": vendedorId,
            "offset": offset,
            "limit": limit
        },
        "device": deviceId
    }
}

export function JsonGetPrismaModel(filialID, somenteDisponivel, deviceId, offset = 0, limit) {
    return {
        "action": "get-prisma",
        "data": {
            "filial_id": filialID,
            "somente_disponivel": somenteDisponivel,
            "limit": limit,
            "offset": offset,
        },
        "device": deviceId
    }
}

export function JsonGetPrecoModel(deviceId, subProId, tabPrecoId, conPagId, qtde) {
    return {
        "action": "get-preco",
        "data": {
            "subpro_id": subProId,
            "tabpre_id": tabPrecoId,
            "conpag_id": conPagId,
            "qtde": qtde
        },
        "device": deviceId
    }
}

export function JsonValidacaoItemModel(deviceId, order) {
    return {
        "action": "validacao-item",
        "data": order,
        "device": deviceId
    }
}

export function JsonRecalcularPrecoModel(deviceId, data) {
    return {
        "action": "get-recalcular-preco",
        "data": data,
        "device": deviceId
    }
}

export function JsonSetOrdemServicoModel(deviceId, data) {
    return {
        "action": "set-ordem-servico",
        "data": data,
        "device": deviceId
    }
}