import { StyleSheet } from "react-native";

//export const styles = (theme, customTheme, font, fontBold) => {
export const styles = () => {
    return StyleSheet.create({
        container: {
            flex: 1,
            // justifyContent: 'center',
            justifyContent: 'flex-end',
            paddingHorizontal: 20,
            paddingVertical: 50,
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
        content: {
            borderRadius: 5,
            padding: 10,
            backgroundColor: '#fff',
        },
        text: {
            textAlign: 'center',
        }
    })
}