import { StyleSheet, Dimensions } from "react-native"
import { colors } from "../../styles/colors"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        safeAreaView: {
            flex: 1,
        },
        container: {
            flex: 1,
            backgroundColor: customTheme.background ? customTheme.background : theme.background,
        },
        viewContainer: {
            flex: 1,
            justifyContent: 'flex-end'
        },
        imageBackground: {
            flex: 1
        },
        imagemIBS: {
            width: 75,
            height: 100,
        },
        buttonTheme: {
            position: 'absolute',
            right: 20,
            top: 15,
        },
        separador: {
            width: '100%',
        },
        viewTop: {
            paddingTop: 15,
            height: '20%'
        },
        viewCenter: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
        },
        viewBottom: {
            paddingBottom: 15,
            height: '15%',
            width: '80%',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        settingsButton: {
            marginTop: 5
        },
        textSettingsButton: {
            fontSize: 16
        },
        buttonInstallPwa: {
            position: 'absolute',
            top: 15,
            left: 15,
            width: 40,
            height: 40,
            //backgroundColor: colors.primary,
            // borderRadius: 5,
            // padding: 5,
            // alignItems: 'center',
            // justifyContent: 'center',
            // flexDirection: 'row'
        }
    })
}