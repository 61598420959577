import { colors } from '../../apps-react-native-core/styles/colors'

//const primaryDark = '#14212b'
const primaryDark = '#2F3748'
const secondaryDark = '#d5dce9'

const primaryLight = '#d8e2e1'
const secondaryLight = '#68baab'

export const LightTheme = {
    primaryColor: primaryLight,
    secondaryColor: colors.DarkLiver,
    background: primaryLight,
    textColor: colors.DarkLiver,

    button: {
        backgroundColor: secondaryLight,
        textColor: colors.DarkLiver,
        textColorBackgroundTransparent: colors.black,
        iconColor: colors.DarkLiver,
        iconColorBackgroundTransparent: colors.DarkLiver,
    },
    textInput: {
        borderColor: colors.cyberGrape,
        borderColorFocused: colors.DarkLiver,
        textColor: colors.DarkLiver,
        placeholderTextColor: colors.DarkLiver,
        iconColor: colors.DarkLiver
    },
    icon: {
        backgroundIcon: secondaryLight,
        iconColor: colors.black,
        iconColorTransparent: colors.DarkLiver,
    },
    card: {
        backgroundColor: secondaryLight
    },
    picker: {
        textColor: colors.DarkLiver
    },
    modal: {
        backgroundColor: colors.lightGray,
        textColor: colors.black
    },
    login: {
        borderColor: secondaryLight,
        backgroundButtonColor: secondaryLight,
        iconButtonColor: colors.DarkLiver,
        textButtonColor: colors.DarkLiver,

    },
    loading: {
        textColor: colors.white
    },
    tabBar: {
        backgroundColor: primaryLight,
        tabBarActiveColor: secondaryLight,
        tabBarInactiveColor: colors.DarkLiver,
    },
    popupMenu: {
        theme: 'light' //Opções: dark, light, default
    },
    flatList: {
        colorFooterSpinner: secondaryLight
    },
    switch: {
        trackColorFalse: '#767577', //cinza claro
        trackColorTrue: '#ab052f',
        thumbColorFalse: '#f4f3f4', //Gelo
        thumbColorTrue: secondaryLight,
    },
    grid: {
        backgroundTitle: secondaryLight,
        textTitleColor: colors.black,
    },
    fabGroup: {
        backgroundColor: secondaryLight,
        color: colors.DarkLiver,
        secondaryBackgroundColor: 'purple',
        secondaryColor: secondaryLight,
        textColor: colors.black,
    }
}

export const DarkTheme = {
    primaryColor: primaryDark,
    secondaryColor: colors.secondaryDark,
    background: primaryDark,
    textColor: colors.white,

    button: {
        backgroundColor: colors.secondaryDark,
        textColor: primaryDark,
        textColorBackgroundTransparent: colors.white,
        iconColor: primaryDark,
        iconColorBackgroundTransparent: colors.white,
    },

    textInput: {
        borderColor: colors.secondaryDark,
        borderColorFocused: colors.white,
        placeholderTextColor: colors.mediumGray,
        textColor: colors.white,
        iconColor: colors.white
    },

    icon: {
        backgroundIcon: colors.secondaryDark,
        iconColor: colors.white,
        iconColorTransparent: colors.white
    },
    picker: {
        textColor: colors.white
    },
    modal: {
        backgroundColor: colors.lightGray,
        textColor: colors.darkGray,
        separadorColor: colors.mediumGray
    },
    loading: {
        textColor: colors.white
    },
    tabBar: {
        backgroundColor: primaryDark,
        tabBarActiveColor: colors.secondaryDark,
        tabBarInactiveColor: colors.white,
    },
    popupMenu: {
        theme: 'dark' //Opções: dark, light, default
    },
    flatList: {
        colorFooterSpinner: colors.secondaryDark
    },
    switch: {
        trackColorFalse: '#767577', //cinza claro
        trackColorTrue: '#358975',
        thumbColorFalse: '#f4f3f4', //Gelo
        thumbColorTrue: colors.secondaryDark,
    },
    grid: {
        backgroundTitle: colors.secondaryDark,
        textTitleColor: colors.black,
    },
    fabGroup: {
        backgroundColor: colors.secondaryDark,
        color: colors.DarkLiver,
        secondaryBackgroundColor: colors.white,
        secondaryColor: colors.secondaryDark,
        textColor: colors.white,
    }
}