export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    lightGray: '#F6F6F6',
    mediumGray: '#D6D6D6',
    darkGray: '#676767',
    
    //tema
    primaryDark: '#2c2c2c',
    secondaryDark: '#F16F1B',

    primaryLight: '#e6e6e6',
    secondaryLight: '#F16F1B',
    
    //laranja
    orange: '#F16F1B',
    orangeLight: '#F7941D',
    orangeDark: '#F04E23',
    //azul
    DodgerBlue: '#1E90FF',
    //Verde
    MediumSeaGreen: '#3CB371',
    //Vermelho
    FireBrick: '#B22222',
    //Amarelo
    Gold: '#FFD700',
    //Cores Específicas Sistema
    ComEstoque: '#3CB371',
    SemEstoque: '#B22222',
    ComEstoqueEmOutrasUnidades: '#FFD700',
    ColorServico: '#1E90FF',
    //Outras
    steelBlue: '#4B86AE',
    cyberGrape: '#4F4781',
    Independence: '#413E60',
    DarkLiver: '#3D3C47',
}