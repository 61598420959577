import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            flexDirection: 'row',
            width: '100%',
            padding: 10         
        },
        linearGradient: {
            backgroundColor: customTheme.background ? customTheme.background : theme.background,
            borderRadius: 20,      
        },
        textHeader: {
            fontSize: 14,
            marginLeft: 10,
            flexShrink: 1
        },
        imageProfile: {
            width: 44,
            height: 44,
            borderRadius: 22,
            borderWidth: 1,
            borderColor: customTheme?.secondaryColor ? customTheme?.secondaryColor : theme.secondaryColor
        },
        viewLeft: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'flex-start',
        },
        viewRight: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            alignSelf: 'center',
        },
        separador: {
            width: '95%',
            alignSelf: 'center',
            marginTop: 5
        },
        button: {
            marginRight: 3
        }
    })
}