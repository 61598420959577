import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        separador: {
            backgroundColor: customTheme.transparency ? `rgba(${customTheme.transparency[0]},${customTheme.transparency[1]},${customTheme.transparency[2]},0.7)` :
                `rgba(${theme.transparency[0]},${theme.transparency[1]},${theme.transparency[2]},0.6)`,
            height: 1,
        }
    })
}