import { useContext, useEffect, useState } from "react"
import { View, Image, Platform } from "react-native";
import { ThemeContext } from "../../contexts/themeContext";
import { IBSWsSettingsContext } from "../../contexts/ibsWsSettingsContext";
import { IBSCompanyContext } from "../../contexts/ibsCompanyContext";
import { IBSUserContext } from "../../contexts/ibsUserContext";
import { createUpdateDB } from "../../services/database/createUpdateDbService";
import { getDeviceId } from "../../utils/utils";
import { getSettings } from "../../services/database/tables/settings";
import { sendAlert } from "../../services/messagingCenter";
import { getUser } from "../../services/database/tables/user";
import { LightTheme, DarkTheme } from "../../../src/styles/themeStyles"
import splash from "../../assets/splash.png"
import styles from "./styles";
import { FontEnum, tablesAsyncStorage } from "../../models/enums";
import { getItem } from "../../services/database/asyncStorageService";

export default function IBSSplashScreen(props) {
    const { setThemeStyleLight, setThemeStyleDark, setActualFont } = useContext(ThemeContext)
    const { setWsSettings, setDeviceId, setUsername, setPassword, setNmAplicativo, setIdApp } = useContext(IBSWsSettingsContext)
    const { setCompany } = useContext(IBSCompanyContext)
    const { setUser } = useContext(IBSUserContext)

    const [logged, setLogged] = useState()
    const [updatedDb, setUpdatedDb] = useState(false)

    // ## UseEffect Gerais ##
    useEffect(() => {
        //setActualFont(FontEnum.openSans)
        if (LightTheme && DarkTheme) {
            setThemeStyleLight(LightTheme)
            setThemeStyleDark(DarkTheme)
        }
    }, [])

    useEffect(() => {
        if (props?.nmAplicativo)
            setNmAplicativo(props?.nmAplicativo)
        if (props?.idApp)
            setIdApp(props?.idApp)
    }, [])

    useEffect(() => {
        if (logged === true) {
            props?.navigation?.reset({
                index: 0,
                routes: [{ name: props?.navigationHome }]
            })
        } else if (logged === false) {
            props?.navigation?.reset({
                index: 0,
                routes: [{ name: props?.navigationLogin }]
            })
        }
    }, [logged])

    // ## UseEffect Nativos (iOs/Android) ##
    useEffect(() => {
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
            async function criarAtualizarBD() {
                await createUpdateDB()
                setUpdatedDb(true)
            }
            criarAtualizarBD()
        }
    }, [])

    useEffect(() => {
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
            if (updatedDb == true) {
                async function setContexts() {

                    //Id unico do dispositivo
                    const devideId = await getDeviceId()
                    setDeviceId(devideId)

                    getSettings().then((ret) => {
                        if (!ret.success)
                            sendAlert(ret.title, ret.message)
                        else {
                            if (ret.object && ret.object.length > 0) {
                                const settings = ret.object[0]
                                //settings
                                setWsSettings(settings)
                                //company
                                setCompany(settings)
                            }
                        }
                    })

                    getUser().then((ret) => {
                        if (!ret.success) {
                            sendAlert(ret.title, ret.message)
                            setLogged(false)
                        }
                        else {
                            if (ret.object && ret.object.length > 0) {
                                const user = ret.object[0]
                                setUser(user)

                                setUsername(user.usr_login)
                                setPassword(user.usr_senha)

                                setLogged(true)
                            } else
                                setLogged(false)
                        }
                    })
                }

                setContexts()
            }
        }
    }, [updatedDb])

    // ## UseEffect Web ##
    useEffect(() => {
        if (Platform.OS === 'web') {
            async function setContexts() {
                //Id unico do dispositivo
                const devideId = await getDeviceId()
                setDeviceId(devideId)

                getItem(tablesAsyncStorage.settings).then((ret) => {
                    if (!ret.success)
                        sendAlert(ret.title, ret.message)
                    else {
                        if (ret.object) {
                            const settings = ret.object
                            //settings
                            setWsSettings(settings)
                            //company
                            setCompany(settings)
                        }
                    }
                })

                getItem(tablesAsyncStorage.user).then((ret) => {
                    if (!ret.success) {
                        sendAlert(ret.title, ret.message)
                        setLogged(false)
                    }
                    else {
                        if (ret.object) {
                            const user = ret.object
                            setUser(user)

                            setUsername(user.usr_login)
                            setPassword(user.usr_senha)

                            setLogged(true)
                        } else
                            setLogged(false)
                    }
                })
            }

            setContexts()
        }
    }, [])

    return <View style={styles.container}>
        <Image style={styles.imageSplash} source={splash} />
    </View>
}

/*
    PROPS

    navigation - componente de navegação
    navigationLogin - Nome da tela de Login
    navigationHome - Nome da tela inicial após logar
    nmAplicativo - Nome do aplicativo (criar no Enum nomeAplicativoEnum)
    idApp - código do aplicativo cadastrado na tabela app_aplicativo
*/