import { useCallback, useContext } from "react";
import { Text } from "react-native";
import { styles } from "./styles";
import { ThemeContext } from "../../contexts/themeContext"
import { formatMask } from "../../utils/utils";

export default function IBSText(props) {
    const { IBSTheme, CustomTheme, font, fontBold } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme, font, fontBold)

    const handleChildren = useCallback((text) => {
        if (props?.mask && text) {
            return formatMask(text, props?.mask)
        }

        return text
    }, [])

    return (
        <Text
            {...props}
            children={props?.mask ? handleChildren(props?.children) : props?.children}
            style={[props?.fontWeight == 'bold' ? style.boldText : style.text, props.style ? props.style : undefined]}
        />
    )
}

/*
    PROPS

    children - Texto desejado
    mask - Se deseja alguma máscara no texto. Exemplo de como fazer a máscara: mask={'(##)#####-####'}
    fontWeigth - normal/bold
        default: normal
    style - Estilos personalizados tem prioridade nos estilos já definidos no campo
        default: Programado no arquivo styles.js
    onPress - Ação que deseja realizar ao clicar no texto
        default: vazio
    numberOfLines - Case deseja limitar número de linhas do texto    

    Fonts:

    https://fonts.google.com/specimen/Tajawal
    https://fonts.google.com/specimen/Kanit
    https://fonts.google.com/specimen/Montserrat
*/