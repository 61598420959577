import { useContext } from "react"
import PreventDoubleOnPressService from "../../services/preventDoubleOnPressService"
import { ThemeContext } from "../../contexts/themeContext"
import { TouchableOpacity } from "react-native"
import { styles } from "./styles"
import IBSText from "../IBSText"
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
// import Ionicons from 'react-native-vector-icons/Ionicons'
// import Entypo from 'react-native-vector-icons/Entypo'
import { Ionicons, Entypo, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'

export default function IBSIconButton(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)
    const PreventTouchableOpacity = PreventDoubleOnPressService(TouchableOpacity, props?.msDelay)

    const getIconComponent = (iconDirectory) => {
        switch (iconDirectory) {
            case 'materialcommunityicons':
                return MaterialCommunityIcons;
            case 'ionicons':
                return Ionicons;
            case 'entypo':
                return Entypo;
            case 'materialIcons':
                return MaterialIcons;
            default:
                return MaterialCommunityIcons;
        }
    }

    const getIconButtonColor = () => {
        const buttonColorKey = props?.transparent ? 'iconColorBackgroundTransparent' : 'iconColor';
        return CustomTheme.button?.[buttonColorKey] || IBSTheme.button?.[buttonColorKey];
    }

    const getTextButtonColor = () => {
        const textColorKey = props?.transparent ? 'textColorBackgroundTransparent' : 'textColor';
        return CustomTheme.button?.[textColorKey] || IBSTheme.button?.[textColorKey];
    }

    const TextoBotao = () => {
        return (
            <IBSText
                style={[style.textButton, { color: getTextButtonColor() }, props?.styleText ? props?.styleText : undefined, props?.icon ? { marginLeft: 6 } : undefined]}
                fontWeight='bold'
            >
                {props?.children}
            </IBSText>
        );
    };

    const IconComponent = getIconComponent(props?.iconDirectory);
    const iconButtonColor = getIconButtonColor();

    return (
        <PreventTouchableOpacity
            {...props}
            style={[
                style.container,
                (props?.useHeightDefault === false || props?.transparent === true) ? undefined : { height: 50 },
                !props?.transparent ? style.containerBackground : undefined,
                props?.style ? props?.style : undefined
            ]}
        >
            {props?.iconAfter && props?.children && TextoBotao()}
            {(props?.iconDirectory || (!props?.iconDirectory && props?.icon)) &&
                <IconComponent
                    name={props?.icon}
                    size={props?.size ? props?.size : 24}
                    color={props?.iconColor ? props?.iconColor : iconButtonColor}
                />
            }
            {!props?.iconAfter && props?.children && TextoBotao()}
        </PreventTouchableOpacity>
    )
}

/*
    PROPS

    transparent - Se necessitar que o botão não tenha background, selecione essa propriedade para eliminar o elevation também
    
    icon - ícone do botão, site de ícones disponíveis: https://oblador.github.io/react-native-vector-icons/
        Default: Sem ícone

    iconColor - Caso necessita de alguma cor diferente da configurada nos temas
    
    iconDirectory - Existem alguns diretórios criados pela comunidade com ícones que podem ser usados, site está disponível na propriedade acima
        Opções: materialcommunityicons, ionicons, entypo, materialIcons
        Default: materialcommunityicons
    
    msDelay - Milissegundos caso queira prevenir duplo toque no botão
        Default: 0
    
    onPress - Ação que será tomada no evento onPress
        NotNull
    
    iconAfter - Se necessita que o ícone venha após o texto no botão
        True/False
    
    style - Estilo do botão (Touchable Opacity)
    
    styleText - Estilo do texto do botão (IBSText)
*/