export const firebaseStorageErrors = {
    'storage/unknown': 'Ocorreu um erro inesperado',
    'storage/object-not-found': 'O objeto especificado não foi encontrado',
    'storage/bucket-not-found': 'O bucket especificado não foi encontrado',
    'storage/project-not-found': 'O projeto Firebase especificado não foi encontrado',
    'storage/quota-exceeded': 'A cota de armazenamento para o projeto Firebase foi excedida',
    'storage/unauthenticated': 'O usuário não está autenticado',
    'storage/unauthorized': 'O usuário não tem permissão para realizar a ação desejada',
    'storage/retry-limit-exceeded': 'A operação de upload ou download foi tentada muitas vezes sem sucesso',
    'storage/invalid-checksum': 'A soma de verificação do arquivo carregado não corresponde à soma de verificação esperada',
    'storage/canceled': 'A operação de upload ou download foi cancelada pelo usuário',
    'storage/invalid-event-name': 'Foi especificado um nome de evento inválido para adicionar ou remover ouvintes de eventos',
    'storage/invalid-url': 'Foi especificada uma URL inválida',
    'storage/invalid-argument': 'Foi passado um argumento inválido para a função',
    'storage/no-default-bucket': 'Nenhuma implantação padrão foi definida para o projeto Firebase',
    'storage/invalid-root-operation': 'A operação é inválida nesse contexto',
    'storage/invalid-format': 'O formato do objeto é inválido',
    'storage/internal-error': 'Ocorreu um erro interno no servidor do Firebase',
    'storage/download-size-limit-exceeded': 'O tamanho máximo de download foi excedido',
    'storage/invalid-argument-count': 'Foi passado um número inválido de argumentos para a função',
    'storage/app-deleted': 'A instância do aplicativo Firebase foi excluída',
    'storage/invalid-url-prefix': 'O prefixo da URL é inválido',
    'storage/cannot-slice-blob': 'O blob não pode ser dividido em fatias menores',
    'storage/server-file-wrong-size': 'O tamanho do arquivo no servidor não corresponde ao tamanho esperado',
    'storage/invalid-credential': 'As credenciais fornecidas para o Firebase são inválidas',
  };
  