import { createContext, useState, useEffect } from "react"
import { LightTheme, DarkTheme } from '../styles/themeStyles'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { ThemeEnum, FontEnum } from "../models/enums"
import { StatusBar } from "expo-status-bar"

export const ThemeContext = createContext({})

export function ThemeProvider({ children }) {
    const [theme, setTheme] = useState(ThemeEnum.dark)
    const [font, setFont] = useState(FontEnum.openSans)
    const [fontBold, setFontBold] = useState(FontEnum.openSansBold)
    const [themeStyleLight, setThemeStyleLight] = useState()
    const [themeStyleDark, setThemeStyleDark] = useState()

    useEffect(() => {
        async function getThemeStorage() {
            const themeStorage = await AsyncStorage.getItem("@theme")
            if (themeStorage) {
                setTheme(themeStorage)
            }
        }
        getThemeStorage()
    }, [])

    useEffect(() => {
        async function getFontStorage() {
            const fontStorage = await AsyncStorage.getItem("@font")
            if (fontStorage) {
                setFont(fontStorage)
            }
        }
        getFontStorage()
    }, [])

    async function setActualTheme(actualTheme) {
        await AsyncStorage.setItem("@theme", actualTheme)
        setTheme(actualTheme)
    }

    async function setActualFont(actualFont) {
        await AsyncStorage.setItem("@font", actualFont)
        setFont(actualFont)
        setFontBold(actualFont + 'Bold')
    }

    return (
        <ThemeContext.Provider value={{
            theme,
            IBSTheme: theme === ThemeEnum.dark ? DarkTheme : LightTheme,
            CustomTheme: theme === ThemeEnum.dark ? themeStyleDark : themeStyleLight,
            setActualTheme,
            font,
            fontBold,
            setActualFont,
            setThemeStyleLight,
            setThemeStyleDark
        }}>
            <StatusBar style={theme === ThemeEnum.dark ? 'light' : 'dark'} />
            {children}
        </ThemeContext.Provider>
    )
}