import React, { useContext, useEffect, useState } from "react"
import IBSLoginView from "../../../apps-react-native-core/components/IBSLoginView";
import { JsonSFTAppLoginModel } from "../../../apps-react-native-core/models/requisitions/SFT/jsonSftModels";
import { IBSWsSettingsContext } from "../../../apps-react-native-core/contexts/ibsWsSettingsContext";
import { sendAlert } from "../../../apps-react-native-core/services/messagingCenter";

const logo = require('../../../assets/ibs-consulta-produtos.png')
const imagemFundo = require('../../../assets/fundo-login-blur-wide.png')

let userAgent = navigator.userAgent || navigator.vendor || window.opera;

export default function LoginView({ navigation }) {
    const { deviceId } = useContext(IBSWsSettingsContext)
    const [imgBackground, setImgBackground] = useState()

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [serviceWorkerRegistered, setServiceWorkerRegistered] = useState(false);
    const [iOs, setIOs] = useState(false);

    useEffect(() => {
        setImgBackground(imagemFundo)
    }, [])

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('./../../../service-worker.js').then(function (registration) {
                console.log('ServiceWorker instalar app registrado com sucesso: ', registration.scope);
                setServiceWorkerRegistered(true);
            }).catch(function (err) {
                console.log('Registro ServiceWorker instalar app falhou: ', err);
            });
        }
    }, []);

    useEffect(() => {
        if (!serviceWorkerRegistered) {
            console.log('Aguardando registro do Service Worker...');
            return;
        }

        const beforeInstallPrompt = (e) => {
            console.log('beforeinstallprompt event fired');
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
        console.log('Event listener added');

        return () => {
            window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
        };
    }, [serviceWorkerRegistered]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && !window.navigator.standalone) {
                setIOs(true);
                sendAlert('Instalação', 'Para instalar o aplicativo, clique no botão de compartilhamento e selecione "Adicionar à Tela de Início"');
            } else {
                if (deferredPrompt) {
                    deferredPrompt.prompt();
                    deferredPrompt.userChoice.then((choiceResult) => {
                        // if (choiceResult.outcome === 'accepted') {
                        //     console.log('User accepted the installation');
                        // } else {
                        //     console.log('User declined the installation');
                        // }
                    });
                }
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [deferredPrompt]);

    const [jsonLogin, setJsonLogin] = useState(JsonSFTAppLoginModel(deviceId))

    const installPWA = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Mostra o prompt de instalação
            deferredPrompt.userChoice.then((choiceResult) => {
                // if (choiceResult.outcome === 'accepted') {
                //     console.log('Usuário aceitou instalar o app');
                // } else {
                //     console.log('Usuário recusou instalar o app');
                // }
            });
        } else
            console.log('Prompt de instalação não disponível');
    };

    return (
        <>
            <IBSLoginView
                showButtonInstallPwa={deferredPrompt || !iOs ? true : false}
                actionButtonInstallPwa={() => installPWA()}
                backgroundImage={imgBackground}
                navigation={navigation}
                logo={logo}
                navigationHome={"ConsultaProduto"}
                jsonLogin={jsonLogin}
            />
        </>
    )
}