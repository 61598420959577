import { createContext, useContext, useEffect, useState } from 'react';
import handleChangeObj from '../utils/handleChangeObj';
import { IBSWsSettingsContext } from './ibsWsSettingsContext';
import { handlerJsonRecalcularPrecoModel } from '../models/requisitions/handlerRequisitions';
import { sendAlert } from '../services/messagingCenter';
import PostRequisition from '../requisitions/wsIBS/postRequisition'

export const OrderContext = createContext();

export function OrderProvider({ children }) {

    const { settings, deviceId, nmAplicativo } = useContext(IBSWsSettingsContext)

    const [orderType, setOrderType] = useState()
    const [orderItemType, setOrderItemType] = useState()
    const [orderInstallmentType, setOrderInstallmentType] = useState()
    const [orderItem, setOrderItem] = useState({})
    const [orderInstallment, setOrderInstallment] = useState({})
    const [orderItems, setOrderItems] = useState([])
    const [orderInstallments, setOrderInstallments] = useState([])
    const [order, setOrder] = useState({
        "item": orderItems,
        "parcela": orderInstallments,
    })

    useEffect(() => {
        if (orderItems?.length > 0) {
            const total = orderItems.reduce((total, item) => total +
                (item[orderItemType.Venda] * item[orderItemType.Quantidade]), 0)

            handleChangeObjOrder([orderType?.Total, orderType?.TotalFatura, 'item'], [total, total, orderItems])
            recalculateInstallments()
        } else if (orderType) {
            setOrderInstallments([])
            handleChangeObjOrder([orderType?.Total, orderType?.TotalFatura, 'parcela', 'item'], [0, 0, [], []])
        }
    }, [orderItems])

    useEffect(() => {
        setOrder(prevOrder => ({ ...prevOrder, parcela: orderInstallments }));
    }, [orderInstallments]);

    function handleChangeObjOrder(prop, value) {
        if (orderType)
            handleChangeObj(prop, value, order, setOrder)
        else
            console.log("OrderType não definido. Defina o orderType antes de usar o handleChangeObjOrder")
    }

    function handleChangeObjItem(prop, value) {
        if (orderItemType)
            handleChangeObj(prop, value, orderItem, setOrderItem)
        else
            console.log("OrderItemType não definido. Defina o orderItemType antes de usar o handleChangeObjItem")
    }

    function addItem() {
        const existingItemIndex = orderItems.findIndex(item => item[orderItemType.SubProID] === orderItem[orderItemType.SubProID]);

        const totalItem = orderItem[orderItemType.Venda] * orderItem[orderItemType.Quantidade];
        const newOrderItem = { ...orderItem, [orderItemType.Total]: totalItem };

        if (existingItemIndex !== -1) {
            const updatedOrderItems = [...orderItems];
            updatedOrderItems[existingItemIndex] = newOrderItem;

            setOrderItems(updatedOrderItems);
        } else {
            const newOrderItems = [...orderItems, newOrderItem];

            setOrderItems(newOrderItems);
        }

        setOrderItem({});
    }

    function checkIsItemEdit(id, boolSetOrderItem = true) {
        // Verifica se o item já está na lista de itens
        if (orderItems?.length === 0) return false

        const index = orderItems?.findIndex((orderItem) => orderItem[orderItemType.SubProID] === id)
        if (index !== -1) {
            if (boolSetOrderItem)
                setOrderItem(orderItems[index])
            return true
        } else {
            return false
        }
    }

    function deleteItem(id) {
        const newOrderItems = orderItems?.filter(orderItem => orderItem[orderItemType.SubProID] !== id)
        setOrderItems(newOrderItems)
    }

    function resetItem() {
        setOrderItem({})
    }

    function resetItems() {
        setOrderItems([])
    }

    function addInstallment() {
        setOrderInstallments([...orderInstallments, orderInstallment])
        setOrderInstallment({})
    }

    function resetInstallment() {
        setOrderInstallment({})
    }

    function resetInstallments() {
        setOrderInstallments([])
    }

    function removeInstallment(index) {
        setOrderInstallments(orderInstallments.filter((item, i) => i !== index))
    }

    function getJsonRecalcularPreco() {
        let tempArrayItens = []
        for (const item of orderItems) {
            let tempObj = {
                "subpro_id": item[orderItemType.SubProID],
                "qtde": item[orderItemType.Quantidade],
                "sugerido": item[orderItemType.Sugerido],
                "valor_un": item[orderItemType.Venda],
            }
            tempArrayItens.push(tempObj)
        }

        const jsonRecalcular = {
            "entida_id": order[orderType.EntidadeID],
            "conpag_id_old": order[orderType.CondicaoPagamentoIDOld] ?? order[orderType.CondicaoPagamentoID],
            "conpag_id_new": order[orderType.CondicaoPagamentoID],
            "item": tempArrayItens
        }

        return jsonRecalcular
    }

    function recalculateInstallments(boolRecalculateItems = false, boolSetNullOldCondPag = false) {
        if (orderItems?.length === 0) return

        PostRequisition(settings, handlerJsonRecalcularPrecoModel(nmAplicativo, deviceId, getJsonRecalcularPreco())).then((ret) => {
            if (!ret.success)
                sendAlert(ret.title, ret.message)
            else {
                if (boolSetNullOldCondPag)
                    handleChangeObjOrder(orderType.CondicaoPagamentoIDOld, null)

                if (boolRecalculateItems && ret?.object?.item?.length > 0) {
                    const updatedOrderItems = [...orderItems]
                    ret.object.item.forEach((item) => {
                        const index = orderItems.findIndex((orderItem) => orderItem[orderItemType.SubProID] === item.subpro_id);
                        if (index !== -1) {
                            const updatedOrderItem = {
                                ...orderItems[index],
                                [orderItemType.Sugerido]: item.sugerido,
                                [orderItemType.Venda]: item.valor_un,
                                [orderItemType.Total]: item.valor_un * orderItems[index][orderItemType.Quantidade]
                            };
                            updatedOrderItems[index] = updatedOrderItem;
                        }
                    })
                    setOrderItems(updatedOrderItems);
                }

                if (ret?.object?.parcela?.length > 0) {
                    const updatedOrderInstallments = ret.object.parcela.map((parcela) => ({
                        [orderInstallmentType.Dias]: parcela.dias,
                        [orderInstallmentType.Percentual]: parcela.perc,
                        [orderInstallmentType.TipoCobrancaDescricao]: parcela.tipcob_desc,
                        [orderInstallmentType.TipoCobrancaID]: parcela.tipcob_id,
                        [orderInstallmentType.Valor]: parcela.valor,
                        [orderInstallmentType.Vencimento]: parcela.venc,
                    }))
                    setOrderInstallments(updatedOrderInstallments);
                }
            }
        })
    }

    function updateInstallment(index, newParcelaData) {
        setOrderInstallments(prevInstallments => {
            const newInstallments = [...prevInstallments];
            newInstallments[index] = newParcelaData;
            return newInstallments;
        });
    }

    function handleChangeInstallment(index, item, prop, value) {
        if (orderInstallmentType) {
            updateInstallment(index, { ...item, [prop]: value })
        } else
            console.log("OrderInstallmentType não definido. Defina o orderInstallmentType antes de usar o handleChangeObjInstallment")
    }

    function clearOrder() {
        setOrderItem({})
        setOrderInstallment({})
        setOrderItems([])
        setOrderInstallments([])
        setOrder({})
    }

    return (
        <OrderContext.Provider value={{
            order,
            orderItem,
            orderInstallment,
            orderItems,
            orderType, orderItemType, orderInstallmentType,
            setOrderType, setOrderItemType, setOrderInstallmentType,
            handleChangeObjOrder,
            handleChangeObjItem,
            handleChangeInstallment,
            addItem, resetItem, resetItems,
            addInstallment, resetInstallment, resetInstallments, removeInstallment,
            recalculateInstallments,
            updateInstallment,
            checkIsItemEdit, deleteItem,
            clearOrder
        }}>
            {children}
        </OrderContext.Provider>
    );
}