import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            flex: 1,
        },
        textInputContainer: {
            height: 40,
            flex: 1,
        },
        separador: {
            marginTop: 10,
        },
        viewRow: {
            flexDirection: 'row'
        },
        viewMaisOpcoes: {
            marginTop: 7
        },
        buttonSearch: {
            height: 40,
            width: 40,
            marginLeft: 5
        },
        textProdNaoEncontrado: {
            paddingTop: 20,
            textAlign: 'center',
            fontSize: 16
        },
        switch: {
            marginTop: 12
        }
    })
}