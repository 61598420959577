import { initializeApp } from 'firebase/app';
import { API_KEY, AUTH_DOMAIN, PROJECT_ID, STORAGE_BUCKET, MESSAGING_SENDER_ID, APP_ID, MEASUREMENT_ID } from "@env"

const firebaseConfig = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGING_SENDER_ID,
    appId: APP_ID,
    measurementId: MEASUREMENT_ID
};

const firebase = initializeApp(firebaseConfig);

export default firebase

/* IMPORTANTE

    UTILIZAR O PADRÃO QUE CRIA AS VARIAVEIS DO SISTEMA DENTRO DO ARQUIVO .ENV NA RAIZ DO PROJETO

*/