import { StyleSheet } from "react-native"

export const styles = (widthCard) => {
    return StyleSheet.create({
        container: {
            marginTop: 10,
            width: widthCard,
        },
        viewTitle: {
            flexDirection: 'row',
            borderRadius: 5,
            padding: 10,
        },
        viewPrinc: {
            padding: 10
        },
        viewRow: {
            flexDirection: 'row',
            marginBottom: 5
        },
        separador: {
            marginBottom: 5
        },
        textTitle: {
            fontSize: 16
        },
        viewLeft: {
            flex: 1,
            justifyContent: 'center',
        },
        viewRight: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-end'
        }
    })
}