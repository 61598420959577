import { StyleSheet } from "react-native"

export const styles = (theme, customTheme, font) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        message: {
            color: customTheme?.loading?.textColor ? customTheme?.loading?.textColor : theme?.loading?.textColor,
            //color: 'white',
            fontFamily: font,
            fontSize: 18
        },
        lottieAnimation: {
            width: 200, 
            height: 200
        },
    })
}