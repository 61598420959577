import React, { useContext } from 'react';
import { View, Platform } from 'react-native';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
//import Icon from 'react-native-vector-icons/MaterialIcons';
import IBSText from '../IBSText';
import { ThemeContext } from '../../contexts/themeContext';
import { styles } from './styles';
import { BlurView } from 'expo-blur';
import IBSSeparator from '../IBSSeparator';
import IBSIcon from '../IBSIcon';
import { MaterialIcons } from '@expo/vector-icons'

export default function IBSPopupMenu(props) {

    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    return (
        <Menu>
            <MenuTrigger>
                <MaterialIcons
                    name="more-vert"
                    size={26}
                    color={CustomTheme?.icon?.iconColorTransparent ? CustomTheme?.icon?.iconColorTransparent : IBSTheme.icon.iconColorTransparent}
                />
            </MenuTrigger>
            <MenuOptions
                customStyles={menuOptionsStyles}
            >
                <View style={style.viewBorder}>
                    <BlurView
                        intensity={Platform.OS === 'ios' ? 40 : 125 }
                        tint={CustomTheme.popupMenu?.theme ? CustomTheme.popupMenu?.theme : IBSTheme.popupMenu?.theme}
                        style={style.blurContainer}
                    >
                        {
                            props?.options?.map((option, index) => (
                                <MenuOption key={index} onSelect={option.action}>
                                    <View style={[style.viewRow, index < props.options.length - 1 ? { marginBottom: 5 } : undefined]}>
                                        <View style={style.viewText}>
                                            <IBSText fontWeight={'bold'} children={option.label} style={style.text} />
                                        </View>
                                        <View style={style.viewIcon}>
                                            <IBSIcon icon={option.icon} iconDirectory={option.iconDirectory} size={20} transparent={true} />
                                        </View>
                                    </View>
                                    {index < props.options.length - 1 &&
                                        <IBSSeparator style={style.separador} />
                                    }
                                </MenuOption>
                            ))
                        }
                    </BlurView>
                </View>
            </MenuOptions>
        </Menu>
    )
}

const menuOptionsStyles = {
    optionsContainer: {
        backgroundColor: 'transparent',
        elevation: 0,
        borderRadius: 10,
    },
};

/*

    options - Menus que serão adicionados no popup, ele deve ser um array de objetos contendo as propriedades label, action, icon, 
        iconDirectory e size caso contenha ícone, esse 3 últimos apenas se vai ícone na opção
    Ex: [{'text': 'Opção 1', 'icon': 'play', 'iconDirectory': 'ionicons', onSelect={() => console.log('Selecionado Opção 1')}}]
    modeFloatingButton - Modo criado em conjunto com o IBSFloatingActionButton, ele faz com que o popup seja exibido no botão flutuante

    size - Tamanho do ícone de 3 pontinhos

*/