export default function handleChangeObj(key, value, obj, setObj) {
    if (Array.isArray(key) && Array.isArray(value)) {
        if (key.length === value.length) {
            let updatedObj = { ...obj };
            for (let i = 0; i < key.length; i++) {
                updatedObj[key[i]] = value[i];
            }
            setObj(updatedObj);
        } else {
            console.error('Key e value devem ter o mesmo tamanho');
        }
    } else if (Array.isArray(key) || Array.isArray(value)) {
        console.error('Key e value devem ser ambos arrays ou ambos variáveis simples');
    } else if (!key && Array.isArray(obj)) {
        setObj([...obj, value]);
    } else {
        setObj({ ...obj, [key]: value });
    }
}

/*

    Função útil para telas onde a informação é guardada toda em objeto, por exemplo, um cadastro de entidade, ao invés de ter vários estados para cada campo
    temos apenas um objeto entidade, essa função é utilizada para alimentar esses campos do objeto.

    Formas de uso:

    1 - passando apenas variáveis simples: handleChangeObj('entida_id', valor, objetoEntidade, setObjetoEntidade)
    2 - com arrays: handleChangeObj(['entida_id', 'entida_nome'], [id, nome], objetoEntidade, setObjetoEntidade)
    3 - Essa função também efetua um push em objetos array, para usar o key deve estar vazio ou nulo e o obj já deve ser um array, mesmo que vazio:
        Ex: handleChangeObj(null, {"propriedade": "Qualquer coisa"}, objetoArray, setObjetoArray)

*/