import { SafeAreaView } from 'react-native';
import { useContext, useState } from "react";
import { ThemeContext } from "../../contexts/themeContext";
import { styles } from "./styles";
import { FlatList, Modal, TouchableOpacity, View } from "react-native";
import IBSText from "../IBSText";
import IBSIconButton from "../IBSIconButton";
import IBSSeparator from "../IBSSeparator"
import IBSTextInput from "../IBSTextInput";

export default function IBSPicker(props) {

    const { IBSTheme, CustomTheme, font } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme, font)

    const [dataLength, setDataLength] = useState(props?.data.length)
    const [searchText, setSearchText] = useState('');

    // const { width, height } = Dimensions.get('window');
    // const portrait = width <= height ? true : false

    const renderItem = ({ item, index }) => (
        <>
            <TouchableOpacity onPress={() => props?.onPress(item)}>
                <IBSText style={style.text} fontWeight={'bold'}>{props?.renderItem(item)}</IBSText>
            </TouchableOpacity>
            {(index < dataLength - 1) &&
                <IBSSeparator style={style.separador} />
            }
        </>
    )

    const handleSearchChange = (text) => {
        setSearchText(text);
    }

    const filteredData = props?.data.filter(item =>
        props?.renderItem(item).toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Modal visible={true} animationType="fade" transparent>
            <SafeAreaView style={style.safeAreaView}>
                <TouchableOpacity onPress={props?.onCancel} style={style.overlay} />
                <View style={[
                    style.modalContainer,
                    { height: '100%' }
                ]}>
                    <IBSTextInput
                        placeholder='Pesquisar'
                        styleContainer={style.searchTextInput}
                        value={searchText}
                        onChangeText={handleSearchChange}
                        icon='search'
                        iconDirectory='ionicons'
                    />
                    <FlatList
                        keyboardShouldPersistTaps='handled'
                        data={filteredData}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => index.toString()}
                        contentContainerStyle={style.listContent}
                    />
                    <IBSIconButton children='Cancelar' transparent={true} style={style.button} onPress={props?.onCancel} />
                </View>
            </SafeAreaView>
        </Modal>
    )
}

/*

    data - array de dados ou objeto para serem mapeados (. 
        - Ex: [1, 2, 3] ou [{"id": 1, "nome": "um"}, {"id": 2, "nome": "dois"}] 
    
    onPress - Ação tomada após usuário clicar no item
        - Ex: onPress={(item) => console.log(item)}
    
    onCancel - Ação ao clicar no botão de cancelar ou fora do modal
        - Ex: onCancel={() => {setVisibleAnoFab(false)}}
    
    renderItem - label de cada registro, ou seja, como o registro será mostrado ao usuário. 
        - Ex: renderItem={(item) => `${item}`}

    *** APENAS IOS E APENAS EM CASOS ONDE É PASSADO UM ARRAY DE OBJETOS COMO DATA, APENAS ARRAY NÃO SERÃO NECESSÁRIAS ***
    
    labelKey - É um ID único necessário para o componente Picker, semelhante ao FlatList, usando o exemplo de array de objetos:
        - labelKey={'id'}

    EXEMPLO USANDO COMO DATA UM ARRAY DE DADOS. const numeros = [1, '2', 3]

    <IBSPicker
        data={numeros}
        onPress={(item) => console.log(item)}
        renderItem={(item) => `${item}`}
        onCancel={() => setVisibleAnoFab(false)}
    />

    EXEMPLO USANDO COMO DATA UM ARRAY DE OBJETOS. const numeros = [{"id": 1, "nome": "um"}, {"id": 2, "nome": "dois"}] 

    <IBSPicker
        data={numeros}
        onPress={(item) => console.log(item.nome)}
        renderItem={(item) => `${item.nome.toString()}`}
        onCancel={() => setVisibleAnoFab(false)}
        labelKey={'id'}
    />        

*/