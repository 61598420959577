import * as Application from 'expo-application';
import { Platform } from 'expo-modules-core';
import * as SecureStore from 'expo-secure-store';
import Constants from 'expo-constants';
import { colors } from '../styles/colors';
import { Sistema } from '../models/enums';
//import CryptoJS from 'crypto-js';
import CryptoJS from 'react-native-crypto-js';

const dayjs = require('dayjs')

export function formatBRDate(date, boolHourInclude = true, customFormat) {
    if (date) {
        if (customFormat)
            return dayjs(date).format(customFormat)
        else {
            if (boolHourInclude)
                return dayjs(date).format('DD/MM/YYYY HH:mm:ss')
            else
                return dayjs(date).format('DD/MM/YYYY')
        }
    }

    return date
}

export function formatPlacaCarro(placa) {
    if (placa) {
        const placaAjustada = placa.replace(/\-/g, '');
        const pt1 = placaAjustada.slice(0, 3)
        const pt2 = placaAjustada.slice(3, 7)
        return `${pt1}-${pt2}`
    }

    return placa
}

export function formatCEP(cep) {
    if (cep) {
        const cepAjustado = cep.replace(/\-/g, '');
        const pt1 = cepAjustado.slice(0, 5)
        const pt2 = cepAjustado.slice(5, 8)
        return `${pt1}-${pt2}`
    }

    return cep
}

export function formatMask(value, mask) {
    //Exemplo Máscara celular: '(##)#####-####'

    let formattedValue = value

    if (mask && value) {
        formattedValue = retirarCaracteresEspeciais(formattedValue)
        for (let i = 0; i < mask.length; i++) {
            if (formattedValue.length === i) {
                break
            } else if (formattedValue.length > i && mask[i] !== '#') {
                formattedValue = formattedValue.slice(0, i) + mask[i] + formattedValue.slice(i)
            }
        }
    }

    return formattedValue
}

export function CalcularNrColunasFlatListETamanhoCard(widthWindow, paddingLateral, MarginCards, tamCartao) {
    /*
        widthWindow - Largura da tela do dispositivo
        paddingLateral - desconsiderar o padding definido nos styles (é feito vezes 2)
        MarginCards - Margin configurada nos estilos do cartão, será feito vezes número de cartões -1
        tamMinCartao - tamanho mínimo do cartão (Exemplo: Width 325)
        1.5 - Usado para o padding chegar a um número próximo de "1 width"
    */
    //let widthWindowCalc = widthWindow - ((paddingLateral * 2) * 1.5)
    let widthWindowCalc = widthWindow - (paddingLateral * 2)
    let numColumns = Math.floor(widthWindowCalc / tamCartao)
    //let widthCard = Math.floor((widthWindowCalc - ((MarginCards * 1.5) * numColumns - 1)) / numColumns)
    let widthCard = Math.floor((widthWindowCalc - (MarginCards * (numColumns - 1))) / numColumns)

    return [numColumns, widthCard]
}

export function soNumeros(valor) {
    return valor?.toString().replace(/[^0-9]/g, '')
}

export function removerZerosAEsquerda(valor) {
    return valor?.toString().replace(/^(0+)(\d)/g, "$2")
}

export function retirarCaracteresEspeciais(str) {
    return str?.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
}

export function isValidCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '') return false;

    // Eliminate known invalid CPFs
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;

    // Validate 1st digit
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;

    // Validate 2nd digit
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;

    return true;
}

export function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (cnpj === '') return false

    if (cnpj.length !== 14) return false

    // Elimina CNPJs invalidos conhecidos
    if (/^(\d)\1+$/.test(cnpj)) return false

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    let digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

    if (resultado != digitos.charAt(0)) return false

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

    if (resultado != digitos.charAt(1)) return false

    return true
}

export async function getDeviceId() {
    if (Platform.OS === 'android') {
        return Application.getAndroidId()
    } else if (Platform.OS === 'ios') {
        let deviceId = await SecureStore.getItemAsync('deviceId')

        if (!deviceId) {
            deviceId = Constants.deviceId

            if (!deviceId) {
                deviceId = await Application.getIosIdForVendorAsync()
                await SecureStore.setItemAsync('deviceId', deviceId)
            } else
                await SecureStore.setItemAsync('deviceId', deviceId)
        }

        return `iOs-${deviceId}`
    } else if (Platform.OS === 'web') {
        let deviceId = localStorage.getItem('deviceId');

        if (!deviceId) {
            deviceId = Math.random().toString(36).substring(2);
            localStorage.setItem('deviceId', deviceId);
        }

        return `web-${deviceId}`;
    }
}

export function getArrayYears(startYear, backwards) {
    const currentYear = new Date().getFullYear();
    const years = [];

    if (backwards) {
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
    } else {
        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }
    }

    return years
}

export function getArrayTipoCliente() {
    return [
        {
            id: 0,
            descricao: 'Pessoa Física'
        },
        {
            id: 1,
            descricao: 'Pessoa Jurídica'
        }
    ]
}

export function getVersion() {
    return Constants.expoConfig?.version
        ? Constants.expoConfig?.version
        : (Constants.manifest2?.extra?.expoClient?.version
            ? Constants.manifest2?.extra?.expoClient?.version
            : '')
}

export function getColorEstoque(qtdEstoque, qtdEstoqueOutrasUnidades) {
    if (qtdEstoque && qtdEstoque > 0)
        return colors.ComEstoque
    else if (qtdEstoqueOutrasUnidades && qtdEstoqueOutrasUnidades > 0)
        return colors.ComEstoqueEmOutrasUnidades
    else
        return colors.SemEstoque
}

export function charValidCampoPlaca(value) {
    //Verifica se o texto possui apenas números e letras e tem no máximo 7 caracteres
    if (/^[0-9a-zA-Z]{0,7}$/.test(value))
        return true

    return false
}

export function verifyFirstColumn(index, numColumns) {
    return numColumns == 1 || index == 0 || ((index + 1) % numColumns) == 1
}

export function verifyFirstLine(index, numColumns) {
    return index < numColumns
}

export function getIdPaisBrasil(sistema) {
    if (sistema == Sistema.volcan)
        return 1
    else
        return 1058
}

export function validaEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
}

export function isFieldRequired(condition, obj) {
    const conditionWithValues = condition.replace(/\b(\w+)\b/g, (match, p1) => {
        if (obj[p1] !== undefined) {
            return JSON.stringify(obj[p1]);
        } else {
            return `'${p1}'`;
        }
    })

    return eval(conditionWithValues);
}

export function formatNumber(num, decimalPlaces) {
    if (typeof num !== 'number' || isNaN(num)) {
        return '';
    }

    return num.toLocaleString('de-DE', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
}

export function calcularTaxaDesconto(valorMaior, valorMenor) {
    const desconto = ((valorMaior - valorMenor) / valorMaior) * 100;
    return desconto
}

export function calcularDesconto(valor, taxa) {
    return valor * (taxa / 100)
}

export function isValidDate(dateString) {
    let day, month, year;

    //Caso vier no formato dd/mm/yyyy
    if (dateString.indexOf("/") > -1) {
        [day, month, year] = dateString.split("/");
    } else {
        [year, month, day] = dateString.split("-");
    }

    // JavaScript conta meses de 0 a 11, então subtraímos 1 do mês
    const date = new Date(year, month - 1, day);

    // Verifica se a data é válida
    if (isNaN(date)) {
        return false;
    }

    // Verifica se o dia e o mês são válidos
    if (date.getFullYear() != year || date.getMonth() + 1 != month || date.getDate() != day) {
        return false;
    }

    return true;
}

export function getCurrentDateTime(format = 'datetime') {
    //parametros - datetime, date, time
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // getMonth retorna um valor de 0 (para janeiro) a 11 (para dezembro), então adicionamos 1 para obter o mês correto
    const day = ("0" + currentDate.getDate()).slice(-2);

    const hours = ("0" + currentDate.getHours()).slice(-2);
    const minutes = ("0" + currentDate.getMinutes()).slice(-2);
    const seconds = ("0" + currentDate.getSeconds()).slice(-2);

    if (format === 'date') {
        return `${year}-${month}-${day}`;
    } else if (format === 'time') {
        return `${hours}:${minutes}:${seconds}`;
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}

export function criptografar(str, secretKey) {
    if (str)
        return CryptoJS.AES.encrypt(str, secretKey).toString();
    else return str
}

export function descriptografar(encryptStr, secretKey) {
    if (encryptStr) {
        const bytes = CryptoJS.AES.decrypt(encryptStr, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else return encryptStr
}