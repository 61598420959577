import React, { useEffect, useState } from 'react'
import Routes from './src/routes'
import { ThemeProvider } from './apps-react-native-core/contexts/themeContext'
import { ProgressDialogProvider } from './apps-react-native-core/contexts/progressDialogContext'
import * as SplashScreen from 'expo-splash-screen';
import loadFonts from './apps-react-native-core/services/fontsService'
import { IBSWsSettingsProvider } from './apps-react-native-core/contexts/ibsWsSettingsContext'
import { IBSUserProvider } from './apps-react-native-core/contexts/ibsUserContext'
import { IBSCompanyProvider } from './apps-react-native-core/contexts/ibsCompanyContext'
import { decode, encode } from 'base-64'
import { MenuProvider } from "react-native-popup-menu";
import { ToastProvider } from './apps-react-native-core/contexts/toastContext';
import { OrderProvider } from './apps-react-native-core/contexts/orderContext';
import { ParametrosProvider } from './apps-react-native-core/contexts/parametrosContext';

export default function App() {

  if (!global.btoa) {
    global.btoa = encode;
  }

  if (!global.atob) {
    global.atob = decode;
  }

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function prepare() {
      await loadFonts()
      setLoading(false)
      SplashScreen.hideAsync()
    }

    prepare();
  }, []);

  if (loading)
    return null

  return (
    <ThemeProvider>
      <ProgressDialogProvider>
        <IBSWsSettingsProvider>
          <IBSUserProvider>
            <IBSCompanyProvider>
              <MenuProvider>
                <ToastProvider>
                  <ParametrosProvider>
                    <OrderProvider>
                      <Routes />
                    </OrderProvider>
                  </ParametrosProvider>
                </ToastProvider>
              </MenuProvider>
            </IBSCompanyProvider>
          </IBSUserProvider>
        </IBSWsSettingsProvider>
      </ProgressDialogProvider>
    </ThemeProvider>
  )
}