//Se criar um novo parâmetro geral ou específico do App, adicionar no Enum
export const parametrosEnum = {
    //Manter em ordem alfabética
    IDNaturezaOperacaoPadrao: 'IDNaturezaOperacaoPadrao',
    IDTabelaPrecoPadrao: 'IDTabelaPrecoPadrao',
    NrCasasDecimais: 'NrCasasDecimais',
    UrlApiIbsConsultaPlaca: 'UrlApiIbsConsultaPlaca',
    MostrarPrecoDeCustoProduto: 'MostrarPrecoDeCustoProduto',
}

/* PARÂMETROS GERAIS */
export const parametrosGerais = {
    NrCasasDecimais: 110,
    UrlApiIbsConsultaPlaca: 447,
}

/* PARÂMETROS ESPECÍFICOS APPS */
export const parametrosIBSAutoCenter = {
    IDTabelaPrecoPadrao: 467,
    IDNaturezaOperacaoPadrao: 491,
    MostrarPrecoDeCustoProduto: 519,
}

export const parametrosGoedertPreVenda = {
    IDTabelaPrecoPadrao: 512,
}

export const parametrosIBSConsultaProdutos = {
    IDTabelaPrecoPadrao: 513,
    MostrarPrecoDeCustoProduto: 520,
}

/* TIPOS PARÂMETROS */
export const tipoParam = {
    char: 'param_char',
    contador: 'param_contador',
    data: 'param_data',
    inteiro: 'param_inteiro',
    logico: 'param_logico',
    numero: 'param_numero'
}

/* PARÂMETROS OBRIGATÓRIOS POR APP */
export const paramsObrigatoriosIBSAutoCenter = [
    {
        param_id: 110,
        param_enum: parametrosEnum.NrCasasDecimais,
        param_tipo: tipoParam.inteiro
    },
    {
        param_id: 467,
        param_enum: parametrosEnum.IDTabelaPrecoPadrao,
        param_tipo: tipoParam.inteiro
    },
    {
        param_id: 491,
        param_enum: parametrosEnum.IDNaturezaOperacaoPadrao,
        param_tipo: tipoParam.inteiro
    },
]

export const paramsObrigatoriosGoedertPreVenda = [
    {
        param_id: 512,
        param_enum: parametrosEnum.IDTabelaPrecoPadrao,
        param_tipo: tipoParam.inteiro
    },
]

export const paramsObrigatoriosIBSConsultaProdutos = [
    {
        param_id: 513,
        param_enum: parametrosEnum.IDTabelaPrecoPadrao,
        param_tipo: tipoParam.inteiro
    },
]