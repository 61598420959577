import * as Font from 'expo-font'
import { Roboto_400Regular, Roboto_700Bold } from '@expo-google-fonts/roboto'
import { OpenSans_400Regular, OpenSans_700Bold } from '@expo-google-fonts/open-sans'
import { Lato_400Regular, Lato_700Bold } from '@expo-google-fonts/lato'
import { Montserrat_400Regular, Montserrat_700Bold } from '@expo-google-fonts/montserrat'
import { Poppins_400Regular, Poppins_700Bold } from '@expo-google-fonts/poppins'
import BuildReturn from '../models/buildReturn'

export default async function loadFonts() {
  try {
    //Sempre colocar o bold como nome da fonte regular + Bold, contexto base de temas espera dessa forma    
    await Font.loadAsync({
      "Roboto": Roboto_400Regular,
      "RobotoBold": Roboto_700Bold,
      "OpenSans": OpenSans_400Regular,
      "OpenSansBold": OpenSans_700Bold,
      "Lato": Lato_400Regular,
      "LatoBold": Lato_700Bold,
      "Montserrat": Montserrat_400Regular,
      "MontserratBold": Montserrat_700Bold,
      "Poppins": Poppins_400Regular,
      "PoppinsBold": Poppins_700Bold
    })
    await new Promise(resolve => setTimeout(resolve, 2000))
  } catch (e) {
    return BuildReturn(false, 'Erro ao carregar fontes', 'Ocorreu um erro ao tentar carregar as fontes do Google', e)
  } finally {
    return BuildReturn(true)
  }
}