import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import LoginView from "./views/login";
import SplashScreenView from "./views/splashScreen";
import ConsultaProdutoView from "./views/consultaProduto";
import Settings1View from "../apps-react-native-core/views/settings/screenSettings1"
import Settings2View from "../apps-react-native-core/views/settings/screenSettings2"

const Stack = createNativeStackNavigator()

export default function Routes() {
    return <NavigationContainer>
        <Stack.Navigator initialRouteName="SplashScreen">
            <Stack.Screen name="SplashScreen" component={SplashScreenView} options={{
                headerShown: false
            }} />
            <Stack.Screen name="Login" component={LoginView} options={{
                headerShown: false,
            }} />
            <Stack.Screen name="ConsultaProduto" component={ConsultaProdutoView} options={{
                headerShown: false,
            }} />
            <Stack.Screen name="Settings1" component={Settings1View} options={{
                headerShown: false,
                animation: 'slide_from_right'
            }} />
            <Stack.Screen name="Settings2" component={Settings2View} options={{
                headerShown: false,
                animation: 'slide_from_right'
            }} />
        </Stack.Navigator>
    </NavigationContainer>
}