import { useContext } from "react";
import { View } from "react-native";
import { ThemeContext } from "../../contexts/themeContext";
import IBSIconButton from "../IBSIconButton";
import IBSTextInput from "../IBSTextInput";
import { styles } from "./styles";

export default function IBSLogin(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)

    const style = styles(IBSTheme, CustomTheme)

    return (
        <View style={style.container}>
            <IBSTextInput
                styleContainer={style.textInputContainer}
                label="Usuário"
                onChangeText={props?.onChangeTextLogin ? props?.onChangeTextLogin : undefined}
                value={props?.valueLogin ? props?.valueLogin : undefined}
                placeholder={'Usuário'}
                icon={'person-outline'}
                iconDirectory={'ionicons'}
                autoCapitalize={'none'}
            />
            <IBSTextInput
                styleContainer={style.textInputContainer}
                label="Senha"
                secureTextEntry={true}
                onChangeText={props?.onChangeTextPassword ? props?.onChangeTextPassword : undefined}
                value={props?.valuePassword ? props?.valuePassword : undefined}
                placeholder={'Senha'}
                icon={'lock-closed-outline'}
                iconDirectory={'ionicons'}
                autoCapitalize={'none'}
            />
            <IBSIconButton
                style={style.ButtonEntrar}
                children='Entrar'
                icon={"login"}
                msDelay={500}
                onPress={props?.onPressLogin ? props?.onPressLogin : undefined}
            />
        </View>
    )
}

/*
    PROPS:

    onPressLogin - Evento ao clicar no botão de entrar
    onPressSettings - Evento ao clicar no botão de configurações
    onChangeTextLogin - Evento que irá acontecer ao alterar texto do campo de usuário
    onChangeTextPassword - Evento que irá acontecer ao alterar texto do campo de senha
    valueLogin - Valor do campo de usuário
    valuePassword - Valor do campo de senha
 */