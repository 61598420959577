import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            justifyContent: 'center'
        },
        backgroundIcon: {
            backgroundColor: customTheme?.icon?.backgroundIcon ? customTheme.icon.backgroundIcon : theme.icon.backgroundIcon,
            borderRadius: 30,
            padding: 4
        },
    })
}