import { useContext } from "react";
import { Modal, Platform, View } from "react-native";
import LottieView from 'lottie-react-native'
import { ThemeContext } from "../../contexts/themeContext";
import { styles } from "./style";
import IBSText from "../IBSText";

export default function IBSLoading(props) {
    const { IBSTheme, CustomTheme, font } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme, font)

    return (
        <Modal visible={props?.visible} transparent={true} animationType="fade">
            <View style={style.container}>
                <View style= {Platform.OS === 'web' ? style.lottieAnimation : undefined}>
                    <LottieView
                        source={require('../../assets/lottieFiles/loading.json')}
                        autoPlay
                        loop
                        style={Platform.OS !== 'web' ? style.lottieAnimation : undefined}
                    />
                </View>
                <View style={style.viewRow}>
                    <IBSText
                        style={style.message}
                        children={props?.message}
                    />
                </View>
            </View>
        </Modal>
    )
}

/*

    PROPS

    visible - Auto explicativo
    message - Auto explicativo

*/