import { StyleSheet } from "react-native"

export const styles = (theme, customTheme, font) => {
    return StyleSheet.create({
        safeAreaView: {
            flex: 1,
            backgroundColor: customTheme?.primaryColor ? customTheme.primaryColor : theme.primaryColor,
        },
        itemPicker: {
            fontFamily: font,
            fontSize: 16,
            color: customTheme?.picker?.textColor ? customTheme.picker.textColor : theme.picker.textColor
        },
        overlay: {
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        modalContainer: {
            // width: '100%',
            paddingHorizontal: 10,
            //paddingVertical: 15
        },
        modalContaineriOs: {
            width: '100%',
        },
        button: {
            height: 40,
            borderTopWidth: 1,
            borderTopColor: 'rgba(255, 255, 255, 0.4)',
        },
        text: {
            //textAlign: 'center',
            //fontSize: 14,
            marginBottom: 11,
            paddingHorizontal: 5,
        },
        separador: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            marginBottom: 11,
        },
        listContent: {
            flexGrow: 1,
            //justifyContent: 'center',
        },
        blurContainer: {
            paddingHorizontal: 10,
            paddingVertical: 15
        },
        searchTextInput: {
            marginBottom: 15,
        },
    })
}