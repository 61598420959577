import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            justifyContent: 'center',
        },
        viewRow: {
            flexDirection: 'row',
            alignItems: 'center',
        }
    })
}