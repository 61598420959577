import { ActivityIndicator, View } from "react-native";
import { ThemeContext } from "../../contexts/themeContext";
import { useContext } from "react";

export default function IBSFooterSpinner() {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)

    return (
        <View style={{ paddingVertical: 10 }}>
            <ActivityIndicator
                size={'large'}
                color={CustomTheme.flatList?.colorFooterSpinner ? CustomTheme.flatList?.colorFooterSpinner : IBSTheme.flatList?.colorFooterSpinner}
            />
        </View>
    )
}