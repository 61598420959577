import { Switch, View } from "react-native";
import IBSText from "../IBSText";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/themeContext";
import { styles } from "./styles";

export default function IBSSwitch(props) {
    const { onValueChange, value, text, textLeft } = props

    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    return (
        <View style={[style.container, props?.style ? props?.style : undefined]}>
            <View style={style.viewRow}>
                {textLeft && text && <IBSText children={text} fontWeight={'bold'} />}
                <Switch
                    trackColor={{
                        false: CustomTheme.switch?.trackColorFalse ? CustomTheme.switch?.trackColorFalse : IBSTheme.switch.trackColorFalse,
                        true: CustomTheme.switch?.trackColorTrue ? CustomTheme.switch?.trackColorTrue : IBSTheme.switch.trackColorTrue
                    }}
                    thumbColor={value
                        ? CustomTheme.switch?.thumbColorTrue ? CustomTheme.switch?.thumbColorTrue : IBSTheme.switch.thumbColorTrue
                        : CustomTheme.switch?.thumbColorFalse ? CustomTheme.switch?.thumbColorFalse : IBSTheme.switch.thumbColorFalse
                    }
                    ios_backgroundColor={CustomTheme.switch?.trackColorFalse ? CustomTheme.switch?.trackColorFalse : IBSTheme.switch.trackColorFalse}
                    onValueChange={onValueChange}
                    value={value}
                />
                {!textLeft && text && <IBSText children={text} fontWeight={'bold'} style={{ marginLeft: 5 }} />}
            </View>
        </View>
    )
}

/*

    PROPS

    style - Caso necessite que a view principal tenha algum estilo
    text - Texto após o switch (não é obrigatório)
    textLeft - Se precisar que o texto fique antes do switch informar True
    onValueChange - ação ao apertar no switch, geralmente é setado uma variável, por exemplo: 
        onValueChange={() => setBoolEstoque(!boolEstoque)}
    value - variável utilizada para setar o switch. Exemplo: 
        value={boolEstoque}


*/