import { useContext } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { ThemeContext } from '../../contexts/themeContext'
import { styles } from './styles'

export default function IBSPressCard(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    return (
        //Recebe uma View por fora com background sólido pois senão no iOs recebe um warning de dificuldades para calcular a sombra
        <View style={[style.view, props.style ? props.style : undefined]}> 
            <TouchableOpacity
                {...props}
                style={[style.container, props.styleContainer ? props.styleContainer : undefined]}
            >
                {props?.children}
            </TouchableOpacity>
        </View>
    )
}

/*
    PROPS

    children
    style - Estilo da View externa
    styleContainer - Estilo do Touchable Opacity
*/