//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
//import Ionicons from 'react-native-vector-icons/Ionicons'
//import Entypo from 'react-native-vector-icons/Entypo'
import { Ionicons, Entypo, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { View } from 'react-native'
import { ThemeContext } from '../../contexts/themeContext'
import { useContext } from 'react'
import { styles } from './styles'

export default function IBSIcon(props) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const style = styles(IBSTheme, CustomTheme)

    const getIconComponent = (iconDirectory) => {
        switch (iconDirectory) {
            case 'materialcommunityicons':
                return MaterialCommunityIcons;
            case 'ionicons':
                return Ionicons;
            case 'entypo':
                return Entypo;
            case 'materialIcons':
                return MaterialIcons;
            default:
                return MaterialCommunityIcons;
        }
    }

    const IconComponent = getIconComponent(props?.iconDirectory);
    const iconColor = CustomTheme?.icon?.iconColor ? CustomTheme?.icon?.iconColor : IBSTheme?.icon?.iconColor
    const iconColorTransparent = CustomTheme?.icon?.iconColorTransparent ? CustomTheme?.icon?.iconColorTransparent : IBSTheme?.icon?.iconColorTransparent

    return (
        <View style={[style.container ,props?.transparent ? undefined : style.backgroundIcon, props?.style ? props?.style : undefined]}>
            {(props?.iconDirectory || (!props?.iconDirectory && props?.icon)) &&
                <IconComponent 
                    name={props?.icon} 
                    size={props?.size ? props?.size : 24} 
                    color={props?.color ? props?.color : props?.transparent ? iconColorTransparent : iconColor} />
            }
            {/* {((!props?.iconDirectory && props?.icon) || props?.iconDirectory == 'materialcommunityicons') &&
                <MaterialCommunityIcons name={props?.icon} size={props?.size ? props?.size : 24} color={props?.color ? props?.color : iconColor} />
            }
            {props?.iconDirectory == 'ionicons' &&
                <Ionicons name={props?.icon} size={props?.size ? props?.size : 24} color={props?.color ? props?.color : iconColor} />
            }
            {props?.iconDirectory == 'entypo' &&
                <Entypo name={props?.icon} size={props?.size ? props?.size : 24} color={props?.color ? props?.color : iconColor} />
            }
            {props?.iconDirectory == 'materialIcons' &&
                <MaterialIcons name={props?.icon} size={props?.size ? props?.size : 24} color={props?.color ? props?.color : iconColor} />
            } */}
        </View>
    )
}

/*
    PROPS

    icon - nome do ícone, pesquisar no link https://oblador.github.io/react-native-vector-icons/
    size - tamanho do ícone
    color - cor do ícone
*/