import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        safeAreaView: {
            flex: 1,
            backgroundColor: customTheme.background ? customTheme.background : theme.background,
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        textEtapa: {
            fontSize: 22,
            marginBottom: 20
        },
        textInformativo: {
            fontSize: 18,
            marginBottom: 50
        },
        textInputContainer: {
            marginBottom: 20,
            flex: 1,
        },
        textInput: {
            //height: 40,
        },
        buttonSearch: {
            width: 50,
            height: 50,
            marginBottom: 20,
            marginLeft: 5
        },
        button: {
            //width: 280,
            height: 50,
            //position: 'absolute',
            //bottom: 20
        },
        viewButton: {
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 25
        },
        viewRow: {
            flexDirection: 'row',
        },
        textEmpresa: {
            width: 280,
            textAlign: 'center'

        }
    })
}