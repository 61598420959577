import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 10,
        },
        viewLeft: {
            alignItems: 'flex-start',
            alignSelf: 'center',
        },
        viewCenter: {
            flex: 1,
            alignSelf: 'center',
        },
        viewRight: {
            flexDirection: 'row',
            paddingRight: 15,
            paddingLeft: 5,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        title: {
            color: customTheme.secondaryColor ? customTheme.secondaryColor : theme.secondaryColor,
        },
        buttonBack: {
            paddingLeft: 15,
            paddingRight: 10,
        }
    })
}