import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        safeAreaView: {
            flex: 1,
            backgroundColor: customTheme.background ? customTheme.background : theme.background,
        },
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        textInformativo: {
            fontSize: 18,
            marginBottom: 50
        },
        textInputContainer: {
            flex: 1,
            marginBottom: 20,
        },
        buttonSearch: {
            width: 50,
            height: 50,
            marginBottom: 20,
            marginLeft: 5
        },
        button: {
            //width: 265,
            height: 50,
        },
        viewButton: {
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 25
        },
        viewRow: {
            flexDirection: 'row'
        },
        viewRowTextInput: {
            flexDirection: 'row',
            width: '80%',
        },
        viewCard: {
            padding: 10,
        },
        textInputTitleCard: {
            marginRight: 5
        },
        textInputCard: {
            marginBottom: 3
        }
    })
}