import React, { useEffect, useState } from 'react';
import { Modal, Text, View, SafeAreaView } from 'react-native';
import { styles } from './styles';
import IBSText from '../IBSText';

let toastTimeout

export default function IBSToast(props) {
    const { visible, funcSetVisible, message, duration } = props
    const [isVisible, setIsVisible] = useState(visible);

    const style = styles()

    useEffect(() => {
        setIsVisible(visible);

        if (visible) {
            toastTimeout = setTimeout(() => {
                setIsVisible(false)
                funcSetVisible()
            }, duration || 1500)
        }

        return () => {
            clearTimeout(toastTimeout)
        }
    }, [visible, duration])

    return (
        <Modal
            animationType="fade"
            transparent
            visible={isVisible}
            onRequestClose={() => {
                setIsVisible(false);
            }}
        >
            {/* <SafeAreaView style={{ flex: 1 }}> */}
                <View style={style.container}>
                    <View style={style.content}>
                        {/* <IBSText fontWeight={'bold'} style={style.text}>{message}</IBSText> */}
                        <Text style={style.text}>{message}</Text>
                    </View>
                </View>
            {/* </SafeAreaView> */}
        </Modal>
    )
}