import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            borderRadius: 5,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerBackground: {
            paddingVertical: 5,
            backgroundColor: customTheme.button?.backgroundColor ? customTheme.button?.backgroundColor : theme.button?.backgroundColor,
            //Android
            elevation: 4,
            // iOS
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
        },
        textButton: {
            marginHorizontal: 3
        },
    })
}