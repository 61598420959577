import { Image, Platform, View } from "react-native";
import { styles } from "./styles"
import IBSText from "../IBSText";
import { IBSUserContext } from "../../contexts/ibsUserContext"
import { useContext, useEffect, useState } from "react";
import IBSIconButton from "../IBSIconButton";
import { ThemeContext } from "../../contexts/themeContext";
import { ThemeEnum, tablesAsyncStorage, transparecyMode } from "../../models/enums";
import { deleteDataUser } from "../../services/database/tables/user";
import { IBSWsSettingsContext } from "../../contexts/ibsWsSettingsContext";
import { ProgressDialogContext } from "../../contexts/progressDialogContext";
import { sendAlert } from "../../services/messagingCenter";
import { LinearGradient } from "expo-linear-gradient";
import IBSPopupMenu from "../IBSPopupMenu";
import { getVersion } from "../../utils/utils";
import { ParametrosContext } from "../../contexts/parametrosContext";
import PostRequisition from "../../requisitions/wsIBS/postRequisition";
import { deleteItem } from "../../services/database/asyncStorageService";

let imageProfileDefault = require('../../assets/sem-imagem.png')
let lacoIBS = require('../../assets/laco-fundo-branco.png')

export default function IBSPersonHeader(props) {

    const { theme, setActualTheme, IBSTheme, CustomTheme } = useContext(ThemeContext)
    const { usrNome, uriUsrFoto, clearUser } = useContext(IBSUserContext)
    const { settings } = useContext(IBSWsSettingsContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)
    const { getParametros } = useContext(ParametrosContext)

    const [imageKey, setImageKey] = useState(0);

    const style = styles(IBSTheme, CustomTheme)

    useEffect(() => {
        setImageKey(imageKey + 1);
    }, [])

    const transparencyDark = CustomTheme?.transparency ? CustomTheme?.transparency == transparecyMode.dark
        : IBSTheme.transparency == transparecyMode.dark

    async function deletarRegistroUsuario() {
        if (Platform.OS === 'web') {
            return await deleteItem(tablesAsyncStorage.user)
        } else {
            return await deleteDataUser()
        }
    }

    const logout = () => {
        showProgressDialog('Efetuando Logout')
        PostRequisition(settings, props?.jsonLogout).then((ret) => {
            if (!ret.success) {
                sendAlert(ret.title, ret.message)
            }

            deletarRegistroUsuario().then((retDb) => {
                hideProgressDialog()
                if (!retDb.success)
                    sendAlert(retDb.title, retDb.message)
                else {
                    clearUser()
                    props?.navigation.reset({
                        index: 0,
                        routes: [{ name: props?.navigationLogout ? props?.navigationLogout : "Login" }]
                    })
                }
            })
        })
    }

    return (
        <>
            <LinearGradient
                colors={transparencyDark ? ['rgba(0,0,0,0.0)', 'rgba(0,0,0,0.1)'] : ['rgba(255,255,255,0.0)', 'rgba(255,255,255,0.1)']}
                style={style.linearGradient}
            >
                <View style={style.container}>
                    <View style={style.viewLeft}>
                        <Image
                            key={imageKey}
                            style={style.imageProfile}
                            source={Platform.OS === 'web' ? lacoIBS : uriUsrFoto ? { uri: uriUsrFoto } : imageProfileDefault}
                        />
                        <IBSText
                            children={Platform.OS === 'web' ? props?.title : `Olá, ${usrNome}`}
                            fontWeight={'bold'} style={style.textHeader}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                        />
                    </View>
                    <View style={style.viewRight}>
                        <IBSIconButton
                            icon={"notifications-outline"}
                            iconDirectory={'ionicons'}
                            style={style.button}
                            size={22}
                            transparent={true}
                        />
                        <IBSPopupMenu
                            options={[
                                {
                                    label: 'Att Parametros',
                                    action: () => { getParametros(true, true) },
                                    icon: "refresh",
                                    iconDirectory: 'ionicons'
                                },
                                {
                                    label: 'Configurações',
                                    action: () => { props?.navigation.navigate('Settings1') },
                                    icon: "settings-outline",
                                    iconDirectory: 'ionicons'
                                },
                                {
                                    label: 'Filial',
                                    action: () => { props?.navigation.navigate('Settings2') },
                                    icon: "office-building-cog",
                                    iconDirectory: 'materialcommunityicons'
                                },
                                {
                                    label: 'Tema',
                                    action: () => setActualTheme(theme === ThemeEnum.dark ? ThemeEnum.light : ThemeEnum.dark),
                                    icon: theme === ThemeEnum.dark ? 'moon-outline' : 'sunny-outline',
                                    iconDirectory: 'ionicons'
                                },
                                {
                                    label: 'Sobre',
                                    action: () => sendAlert('Informações', `\nDesenvolvido por IBS Sistemas Ltda.\n\nVersão ${getVersion()}`),
                                    icon: "information-circle-outline",
                                    iconDirectory: 'ionicons'
                                },
                                {
                                    label: 'Logout',
                                    action: () => logout(),
                                    icon: "log-out-outline",
                                    iconDirectory: 'ionicons'
                                },
                            ]}
                        />
                    </View>
                </View>
            </LinearGradient>
        </>
    )
}

/*

    PROPS

    jsonLogout - Json que efetuará o logout no servidor
    navigation - componente de navegação
    navigationLogout - Se o nome da tela de login for diferente de "Login", passar o nome da tela
        Default: "Login"

*/