import { FlatList } from "react-native";
import IBSFooterSpinner from "../IBSFooterSpinner";
import { useState } from "react";
import IBSText from "../IBSText";

export default function IBSFlatList(props) {
    const { styleText, windowSize, limit } = props
    
    const [loading, setLoading] = useState(false)

    const handleOnEndReached = async () => {
        const searchEnd = props?.searchEnd || props?.data?.length > 0 && props?.data?.length % limit != 0
        
        if (!searchEnd && !loading) {
            setLoading(true)            
            await props?.onEndReached()            
            setLoading(false)
        }
    }

    return (
        <>
            {props?.searchEmpty && <IBSText style={styleText} children={'Nenhum registro encontrado'} />}
            <FlatList
                {...props}
                keyboardShouldPersistTaps='handled'
                ListFooterComponent={loading && <IBSFooterSpinner />}
                windowSize={windowSize ? windowSize : 12}
                onEndReached={handleOnEndReached}
            />
        </>
    )
}

/*

    PROPS

    style - estilo do flatList se necessitar
    data - Enviar exatamente igual a forma de consumo do mesmo campo em um FlatList
    renderItem - Enviar exatamente igual a forma de consumo do mesmo campo em um FlatList
    keyExtractor - Enviar exatamente igual a forma de consumo do mesmo campo em um FlatList

    styleText - Estilo do texto que aparece "Nenhum registro encontrado"
    onEndReached - Ação que será executada ao chegar ao final da flatList
    windowSize - Quantidade de itens renderizados que estão fora da tela. Default: 12
    limit - limite da pesquisa que será feita no ws de consulta
        - É utilizado para o componente verificar se a pesquisa já chegou ao final e não efetuar consultar desnecessárias
    
    searchEmpty - Variável que irá controlar se deve aparecer a mensagem "Nenhum registro encontrado". Deve ser controlada pelo componente pai que o chamou.
        Obs: Se necessitar de um exemplo de controle, consultar tela consultaProduto do projeto ibs-auto-center. src > components > consultaProduto
    
    searchEnd - Variável que irá controlar se a pesquisa já chegou ao final e não efetuar consultar desnecessárias. Deve ser controlada pelo componente pai que o chamou.
        Obs: Se necessitar de um exemplo de controle, consultar tela consultaProduto do projeto ibs-auto-center. src > components > consultaProduto    

*/