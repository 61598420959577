import { useContext, useEffect, useState } from "react";
import { Keyboard, View, Dimensions, Platform } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import IBSIconButton from "../../../components/IBSIconButton";
import IBSText from "../../../components/IBSText";
import IBSTextInput from "../../../components/IBSTextInput";
import { ProgressDialogContext } from "../../../contexts/progressDialogContext";
import { ThemeContext } from "../../../contexts/themeContext";
import { styles } from "./style";
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import firebase from "../../../services/firebaseConfig"
import { GS_ARQ_CONFIG } from "@env"
import { sendAlert } from "../../../services/messagingCenter"
import * as FileSystem from 'expo-file-system';
import { IBSWsSettingsContext } from "../../../contexts/ibsWsSettingsContext";
import { BuilderTechnology, tablesAsyncStorage } from "../../../models/enums";
import { isValidCNPJ, soNumeros } from "../../../utils/utils";
import { firebaseStorageErrors } from "../../../models/firebase/firebaseStorageErrors";
import { addSettings, clearSettingsFilial, getSettings, updateSettingsEmpresa } from "../../../services/database/tables/settings";
import IBSHeader from "../../../components/IBSHeader";
import { IBSCompanyContext } from "../../../contexts/ibsCompanyContext";
import { IBSUserContext } from "../../../contexts/ibsUserContext";
import { useToast } from "../../../contexts/toastContext";
import { getItem, setItem, updateItems } from "../../../services/database/asyncStorageService";

export default function Settings1View({ navigation }) {
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)
    const { builderTechnology, setWsSettings, settings } = useContext(IBSWsSettingsContext)
    const { setNmEmpresa, setIdFilial, setNmFilial, setNmFilialExibicao } = useContext(IBSCompanyContext)
    const { usrCodigo } = useContext(IBSUserContext)
    const { showToast } = useToast()

    const [cnpj, setCnpj] = useState('')
    const [config, setConfig] = useState({})
    const [empresa, setEmpresa] = useState('')
    const [screenWidth, setScreenWidth] = useState(Dimensions.get("window").width)

    const style = styles(IBSTheme, CustomTheme)

    useEffect(() => {
        const updateWidth = () => {
            setScreenWidth(Dimensions.get("window").width)
        }
        const eventUpdateWidth = Dimensions.addEventListener("change", updateWidth);
        return () => eventUpdateWidth.remove()
    }, [])

    const salvar = async () => {
        if (empresa) {
            if (Platform.OS === 'web') {
                getItem(tablesAsyncStorage.settings).then((ret) => {
                    if (!ret.success)
                        sendAlert(ret.title, ret.message)
                    else {
                        if (ret.object && ret.object.length > 0) {
                            updateItems([tablesAsyncStorage.settings], [config]).then((ret) => {
                                if (!ret.success)
                                    sendAlert(ret.title, ret.message)
                                else {
                                    showToast('Configurações atualizadas com sucesso')
                                    navigation.goBack()
                                }
                            })
                        } else {
                            if (usrCodigo) {
                                //Se possui código de usuário, preciso manter a config de filial
                                config.id_filial = ret.object?.id_filial
                                config.nm_filial = ret.object?.nm_filial
                                config.nm_filial_exibicao = ret.object?.nm_filial_exibicao
                            }
                            setItem(tablesAsyncStorage.settings, config).then((ret) => {
                                if (!ret.success)
                                    sendAlert(ret.title, ret.message)
                                else {
                                    showToast('Configurações salvas com sucesso')
                                    navigation.goBack()
                                }
                            })
                        }

                        if (!usrCodigo) { //Limpar filiais apenas se o usuário não está logado
                            updateItems([tablesAsyncStorage.settings], [config]).then((ret) => {
                                if (!ret.success)
                                    sendAlert(ret.title, ret.message)
                                else {
                                    showToast('Configurações atualizadas com sucesso')
                                    navigation.goBack()
                                }
                            })
                        }
                    }
                })
            } else {
                getSettings().then((ret) => {
                    if (!ret.success)
                        sendAlert(ret.title, ret.message)
                    else {
                        if (ret.object && ret.object.length > 0) {
                            updateSettingsEmpresa(config).then((ret) => {
                                if (!ret.success)
                                    sendAlert(ret.title, ret.message)
                                else {
                                    showToast('Configurações atualizadas com sucesso')
                                    navigation.goBack()
                                }
                            })
                        } else {
                            addSettings(config).then((ret) => {
                                if (!ret.success)
                                    sendAlert(ret.title, ret.message)
                                else {
                                    showToast('Configurações salvas com sucesso')
                                    navigation.goBack()
                                }
                            })
                        }

                        if (!usrCodigo) { //Limpar filiais apenas se o usuário não está logado
                            clearSettingsFilial().then((retClear) => {
                                if (!retClear.success)
                                    sendAlert(retClear.title, retClear.message)
                                else {
                                    setIdFilial(0)
                                    setNmFilial('')
                                    setNmFilialExibicao('')
                                }
                            })
                        }
                    }
                })
            }
        }
        else sendAlert('Informe um CNPJ', 'Informe um cnpj e pesquise na lupa para buscarmos a configuração automaticamente')
    }

    const pesquisarEmpresa = async () => {
        Keyboard.dismiss()
        let tempCnpj = soNumeros(cnpj)
        if (tempCnpj) {
            if (isValidCNPJ(tempCnpj)) {
                if (!usrCodigo || (settings?.cnpj && settings.cnpj == tempCnpj)) {
                    if (empresa)
                        setEmpresa('')
                    showProgressDialog('Buscando configurações')
                    try {
                        let nmFile = GS_ARQ_CONFIG + `${tempCnpj}.json`
                        const storage = getStorage(firebase)
                        const gsReference = ref(storage, nmFile)
                        getDownloadURL(gsReference)
                            .then(async (link) => {
                                if (builderTechnology === BuilderTechnology.reactNativeCLI) {
                                    //Desenvolver para React Native CLI
                                    //Acredito que quando for desenvolver precisa passar essa parte abaixo para outro arquivo por causa do import do expo-file-system
                                } else {
                                    if (Platform.OS === 'web') {
                                        const response = await fetch(link);
                                        const blob = await response.blob();
                                        const reader = new FileReader();

                                        reader.onloadend = function () {
                                            //Busco agora o arquivo de config do nginx
                                            let nmFileNginx = GS_ARQ_CONFIG + `config-nginx.json`
                                            const gsReferenceNginx = ref(storage, nmFileNginx)
                                            getDownloadURL(gsReferenceNginx)
                                                .then(async (link) => {
                                                    const response = await fetch(link);
                                                    const blob = await response.blob();
                                                    const readerNginx = new FileReader();

                                                    readerNginx.onloadend = function () {
                                                        const objEmpresa = JSON.parse(reader.result)
                                                        const objNginx = JSON.parse(readerNginx.result)
                                                        const combinedObj = { ...objEmpresa, ...objNginx }
                                                        alimentarConfig(combinedObj)
                                                        hideProgressDialog();
                                                    }
                                                    readerNginx.readAsText(blob);
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                    hideProgressDialog()
                                                    const errorCode = error.code
                                                    const errorMessage = firebaseStorageErrors[errorCode]
                                                    sendAlert('Exceção', `Não conseguimos buscar o arquivo de configuração do Nginx\n\nLog: ${errorMessage ? errorMessage : error}`)
                                                })
                                        }
                                        reader.readAsText(blob);
                                    } else {
                                        const downloadResumable = FileSystem.createDownloadResumable(link, FileSystem.documentDirectory + `${tempCnpj}.json`, {}, () => { });
                                        const downloadResponse = await downloadResumable.downloadAsync();
                                        const fileUri = downloadResponse.uri;

                                        const retJson = await FileSystem.readAsStringAsync(fileUri);
                                        alimentarConfig(JSON.parse(retJson))

                                        hideProgressDialog();
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                hideProgressDialog()
                                const errorCode = error.code
                                const errorMessage = firebaseStorageErrors[errorCode]
                                sendAlert('Exceção', `Não conseguimos buscar o arquivo de configuração\n\nLog: ${errorMessage ? errorMessage : error}`)
                            })
                    } catch (error) {
                        hideProgressDialog()
                        sendAlert('Exceção', `Não conseguiu buscar as configurações\n\nLog: ${error}`)
                    }
                } else {
                    setCnpj('')
                    sendAlert('CNPJ Inválido', 'CNPJ informado difere da configuração inicial do App, se deseja trocar de empresa necessário efetuar logout')
                }
            } else {
                sendAlert('CNPJ Incorreto', 'O CNPJ informado não é válido')
            }
        }
    }

    const alimentarConfig = (config) => {
        if (config) {
            setConfig(config)
            setWsSettings(config)
            setNmEmpresa(config.nm_empresa)
            setEmpresa(config.nm_empresa)
        }
    }

    return <SafeAreaView style={style.safeAreaView}>
        <IBSHeader headerText={'Configuração'} navigation={navigation} backButton={true} />
        <View style={style.container}>
            <IBSText
                style={style.textInformativo}
                children='Informe o CNPJ da Empresa'
            />
            <View style={[style.viewRow, screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}>
                <IBSTextInput
                    label='CNPJ'
                    style={style.textInput}
                    styleContainer={style.textInputContainer}
                    value={cnpj}
                    keyboardType={'numeric'}
                    mask={'##.###.###/####-##'}
                    maxLength={18}
                    onChangeText={(value) => setCnpj(value)}
                    onSubmitEditing={() => pesquisarEmpresa()}
                    placeholder={'Informe o CNPJ'}
                    icon={'office-building'}
                />
                <IBSIconButton
                    icon='search'
                    iconDirectory='materialIcons'
                    style={style.buttonSearch}
                    onPress={() => pesquisarEmpresa()}
                />
            </View>
            <IBSText
                children={empresa}
                style={style.textEmpresa}
            />
        </View>
        <View style={style.viewButton}>
            <IBSIconButton
                icon='save'
                iconDirectory='materialIcons'
                iconAfter={true}
                children='Salvar'
                style={[style.button, screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}
                size={20}
                onPress={salvar}
            />
        </View>
    </SafeAreaView>
}