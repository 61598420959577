import { StyleSheet } from "react-native";

export const styles = (theme, customTheme, font, fontBold) => {
    return StyleSheet.create({
        text: {
            fontFamily: font ? font : "Montserrat",
            fontWeight: "normal",
            color: customTheme.textColor ? customTheme.textColor : theme.textColor
        },
        boldText: {
            fontFamily: fontBold ? fontBold : "MontserratBold",
            fontWeight: "normal",
            color: customTheme.textColor ? customTheme.textColor : theme.textColor
        }
    })
}