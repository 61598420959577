import { createContext, useRef, useState } from "react"
import IBSLoading from "../components/IBSLoading"

export const ProgressDialogContext = createContext({})

export function ProgressDialogProvider({ children }) {
    const [progressDialogVisible, setProgressDialogVisible] = useState(false)
    const [progressDialogMessage, setProgressDialogMessage] = useState('')
    const [progressDialogMessageRetry, setProgressDialogMessageRetry] = useState('')
    const varControlShowingProgress = useRef(false);
    const value = {
        showProgressDialog: (message) => {
            varControlShowingProgress.current = true;
            setProgressDialogVisible(true)
            setProgressDialogMessage(message)
        },
        hideProgressDialog: () => {
            if (varControlShowingProgress.current) {
                varControlShowingProgress.current = false;
                setTimeout(() => {
                    setProgressDialogVisible(false)
                }, 750)
            } else {
                setProgressDialogVisible(false)
            }
        },
        hideProgressDialogAsync: async () => {
            if (varControlShowingProgress.current) {
                varControlShowingProgress.current = false;
                await new Promise(resolve => setTimeout(resolve, 750));
                setProgressDialogVisible(false);
            } else {
                setProgressDialogVisible(false);
            }
        },
        showMessageRetry: (attempt) => {
            setProgressDialogMessageRetry(`Nova tentativa em alguns segundos...\n\nTentativa ${attempt} de ${3}`)
        },
        hideMessageRetry: () => {
            setProgressDialogMessageRetry('')
        }
    }

    return (
        <ProgressDialogContext.Provider value={value}>
            <>
                {
                    progressDialogVisible &&
                    <IBSLoading visible={progressDialogVisible} message={progressDialogMessageRetry ? progressDialogMessageRetry : progressDialogMessage} />
                }
                {children}
            </>
        </ProgressDialogContext.Provider>
    )
}