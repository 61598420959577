import { createContext, useState } from "react"
//import { Sistema } from "../models/enums"

export const IBSCompanyContext = createContext({})

export function IBSCompanyProvider({ children }) {
    //const [sistema, setSistema] = useState(Sistema.volcan)
    const [nmEmpresa, setNmEmpresa] = useState('')
    const [idFilial, setIdFilial] = useState(0)
    const [nmFilial, setNmFilial] = useState('')
    const [nmFilialExibicao, setNmFilialExibicao] = useState('')

    function setCompany(company) {
        if (company?.nm_empresa)
            setNmEmpresa(company.nm_empresa)
        if (company?.id_filial)
            setIdFilial(company.id_filial)
        if (company?.nm_filial)
            setNmFilial(company.nm_filial)
        if (company?.nm_filial_exibicao)
            setNmFilialExibicao(company.nm_filial_exibicao)
    }

    return (
        <IBSCompanyContext.Provider value={{
            //sistema,
            //setSistema,
            nmEmpresa,
            setNmEmpresa,
            idFilial,
            setIdFilial,
            nmFilial,
            setNmFilial,
            nmFilialExibicao,
            setNmFilialExibicao,
            setCompany
        }}>
            {children}
        </IBSCompanyContext.Provider>
    )
}