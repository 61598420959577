import { StyleSheet } from "react-native"

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            padding: 10,
        },
        safeAreaView: {
            flex: 1,
            backgroundColor: customTheme.background ? customTheme.background : theme.background
        }
    })
}