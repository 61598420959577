import { transparecyMode } from '../models/enums'
import { colors } from './colors'

export const LightTheme = {
    primaryColor: colors.primaryLight,
    secondaryColor: colors.secondaryLight,
    background: colors.primaryLight,
    textColor: colors.DarkLiver,
    transparency: transparecyMode.dark,

    button: {
        backgroundColor: colors.secondaryLight,
        textColor: colors.DarkLiver,
        textColorBackgroundTransparent: colors.black,
        iconColor: colors.DarkLiver,
        iconColorBackgroundTransparent: colors.DarkLiver,
    },
    textInput: {
        borderColor: colors.cyberGrape,
        borderColorFocused: colors.DarkLiver,
        textColor: colors.DarkLiver,
        placeholderTextColor: colors.DarkLiver,
        iconColor: colors.DarkLiver
    },
    icon: {
        backgroundIcon: colors.secondaryLight,
        iconColor: colors.black,
        iconColorTransparent: colors.DarkLiver,
    },
    card: {
        backgroundColor: colors.secondaryLight
    },
    picker: {
        textColor: colors.DarkLiver
    },
    modal: {
        backgroundColor: colors.lightGray,
        textColor: colors.black
    },
    login: {
        borderColor: colors.secondaryLight,
        backgroundButtonColor: colors.secondaryLight,
        iconButtonColor: colors.DarkLiver,
        textButtonColor: colors.DarkLiver,

    },
    loading: {
        textColor: colors.white
    },
    tabBar: {
        backgroundColor: colors.primaryLight,
        tabBarActiveColor: colors.secondaryLight,
        tabBarInactiveColor: colors.DarkLiver,
    },
    popupMenu: {
        theme: 'light' //Opções: dark, light, default
    },
    flatList: {
        colorFooterSpinner: colors.secondaryLight
    },
    switch: {
        trackColorFalse: '#767577', //cinza claro
        trackColorTrue: '#ab052f',
        thumbColorFalse: '#f4f3f4', //Gelo
        thumbColorTrue: colors.secondaryLight,
    },
    grid: {
        backgroundTitle: colors.secondaryLight,
        textTitleColor: colors.black,
    },
    fabGroup: {
        backgroundColor: colors.secondaryLight,
        color: colors.DarkLiver,
        secondaryBackgroundColor: 'purple',
        secondaryColor: colors.secondaryLight,
        textColor: colors.black,
        transparency: transparecyMode.light,
    }
}

export const DarkTheme = {
    primaryColor: colors.primaryDark,
    secondaryColor: colors.secondaryDark,
    background: colors.primaryDark,
    textColor: colors.white,
    transparency: transparecyMode.light,

    button: {
        backgroundColor: colors.secondaryDark,
        textColor: colors.primaryDark,
        textColorBackgroundTransparent: colors.white,
        iconColor: colors.primaryDark,
        iconColorBackgroundTransparent: colors.white,
    },

    textInput: {
        borderColor: colors.secondaryDark,
        borderColorFocused: colors.white,
        placeholderTextColor: colors.mediumGray,
        textColor: colors.white,
        iconColor: colors.white
    },

    icon: {
        backgroundIcon: colors.secondaryDark,
        iconColor: colors.white,
        iconColorTransparent: colors.white
    },
    picker: {
        textColor: colors.white
    },
    modal: {
        backgroundColor: colors.lightGray,
        textColor: colors.darkGray,
        separadorColor: colors.mediumGray
    },
    loading: {
        textColor: colors.white
    },
    tabBar: {
        backgroundColor: colors.primaryDark,
        tabBarActiveColor: colors.secondaryDark,
        tabBarInactiveColor: colors.white,
    },
    popupMenu: {
        theme: 'dark' //Opções: dark, light, default
    },
    flatList: {
        colorFooterSpinner: colors.secondaryDark
    },
    switch: {
        trackColorFalse: '#767577', //cinza claro
        trackColorTrue: '#358975',
        thumbColorFalse: '#f4f3f4', //Gelo
        thumbColorTrue: colors.secondaryDark,
    },
    grid: {
        backgroundTitle: colors.secondaryDark,
        textTitleColor: colors.black,
    },
    fabGroup: {
        backgroundColor: colors.secondaryDark,
        color: colors.DarkLiver,
        secondaryBackgroundColor: colors.white,
        secondaryColor: colors.secondaryDark,
        textColor: colors.white,
        transparency: transparecyMode.dark,
    }
}