export const ThemeEnum = {
    dark: 'dark',
    light: 'light'
}

export const FontEnum = {
    roboto: 'Roboto',
    robotoBold: 'RobotoBold',
    openSans: 'OpenSans',
    openSansBold: 'OpenSansBold',
    lato: 'Lato',
    latoBold: 'LatoBold',
    montSerrat: 'Montserrat',
    montSerratBold: 'MontserratBold',
    poppins: 'Poppins',
    poppinsBold: 'PoppinsBold'
    //Sempre finalizar a font Bold com o nome fonte + Bold, o contexto base dos temas espera dessa forma
}

export const VolcanPainelOperSitEnum = {
    aberto: 0,
    iniciado: 1,
    pausado: 2,
    finalizado: 3,
    cancelado: 4
}

export const Sistema = {
    volcan: 'volcan',
    sisfatura: 'sisfatura'
}

export const BuilderTechnology = {
    expoGo: 'expoGo',
    reactNativeCLI: 'reactNativeCLI'
}

export const transparecyMode = {
    dark: [0, 0, 0, 0.1],
    light: [255, 255, 255, 0.2]
}

export const NomeAplicativoEnum = {
    ibsAutoCenter: 'ibs-auto-center',
    ibsConsultaProdutos: 'ibs-consulta-produtos',
    ibsConferencia: 'ibs-conferencia',
    goedertPreVenda: 'goedert-pre-venda',
}

export const tablesAsyncStorage = {
    settings: 'settings',
    user: 'user'
}