import { View } from "react-native";
import { useContext, useEffect, useState } from "react";
import { styles } from "./styles";
import { SafeAreaView } from "react-native-safe-area-context";
import { IBSWsSettingsContext } from "../../../apps-react-native-core/contexts/ibsWsSettingsContext";
import { ThemeContext } from "../../../apps-react-native-core/contexts/themeContext";
import { JsonSFTAppLogoutModel } from "../../../apps-react-native-core/models/requisitions/SFT/jsonSftModels";
import ConsultaProdutoBase from "../../../apps-react-native-core/views/produto/consultaProduto"
import IBSPersonHeader from "../../../apps-react-native-core/components/IBSPersonHeader";
import { ParametrosContext } from "../../../apps-react-native-core/contexts/parametrosContext";

export default function ConsultaProdutoView({ navigation }) {
    
    const { deviceId } = useContext(IBSWsSettingsContext)
    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const { getParametros } = useContext(ParametrosContext)

    const style = styles(IBSTheme, CustomTheme)

    const [jsonLogout, setJsonLogout] = useState(JsonSFTAppLogoutModel(deviceId))

    useEffect(() => {
        getParametros() //Carregar parâmetros ao iniciar aplicativo
    }, [])

    return (
        <SafeAreaView style={style.safeAreaView} edges={['top']}>
            <IBSPersonHeader navigation={navigation} jsonLogout={jsonLogout} title={'IBS Consulta Produtos'} />
            <View style={style.container}>
                <ConsultaProdutoBase />
            </View>
        </SafeAreaView>
    )
}