import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        view: {
            backgroundColor: customTheme.background ? customTheme.background : theme.background,
            borderRadius: 5,
            //Android
            elevation: 5,
            //iOs
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
        },
        container: {
            backgroundColor: customTheme.transparency ? `rgba(${customTheme.transparency[0]},${customTheme.transparency[1]},${customTheme.transparency[2]},${customTheme.transparency[3]})` :
                `rgba(${theme.transparency[0]},${theme.transparency[1]},${theme.transparency[2]},${theme.transparency[3]})`,
            borderRadius: 5,
        },
    })
}