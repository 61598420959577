import React, { useContext, useEffect, useState } from "react"
import { View, Image, ImageBackground, Dimensions, Platform } from "react-native"
import { SafeAreaView } from 'react-native-safe-area-context';
import { styles } from "./styles"
import { ThemeContext } from "../../contexts/themeContext"
import IBSLogin from "../IBSLogin"
import IBSIconButton from "../IBSIconButton";
import IBSSeparator from "../IBSSeparator";
import { ThemeEnum, tablesAsyncStorage } from "../../models/enums";
import { sendAlert } from "../../services/messagingCenter";
import { IBSUserContext } from "../../contexts/ibsUserContext";
import { getSettings } from "../../services/database/tables/settings";
import { IBSWsSettingsContext } from "../../contexts/ibsWsSettingsContext";
import { addUser } from "../../services/database/tables/user";
import { ProgressDialogContext } from "../../contexts/progressDialogContext";
import PostRequisition from "../../requisitions/wsIBS/postRequisition";
import { getItem, setItem } from "../../services/database/asyncStorageService";
import { CHAVE_PASS_USER } from "@env"
import { criptografar } from "../../utils/utils";

export default function IBSLoginView(props) {
    const { theme, IBSTheme, CustomTheme, setActualTheme } = useContext(ThemeContext)
    const { usrLogin, setUsrLogin, usrSenha, setUsrSenha, setUser } = useContext(IBSUserContext)
    const { setUsername, setPassword, settings } = useContext(IBSWsSettingsContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)

    const style = styles(IBSTheme, CustomTheme)

    const [usrSenhaTela, setUsrSenhaTela] = useState('')
    const [screenWidth, setScreenWidth] = useState(Dimensions.get("window").width)

    useEffect(() => {
        const updateWidth = () => {
            setScreenWidth(Dimensions.get("window").width)
        }
        const eventUpdateWidth = Dimensions.addEventListener("change", updateWidth);
        return () => eventUpdateWidth.remove()
    }, [])

    const setLogin = (value) => {
        setUsrLogin(value)
        setUsername(value)
    }

    const setSenha = (value) => {
        setUsrSenhaTela(value)
        if (Platform.OS === 'web')
            value = criptografar(value, CHAVE_PASS_USER)
        setPassword(value)
        setUsrSenha(value)
    }

    async function buscarConfig() {
        if (Platform.OS === 'web') {
            return await getItem(tablesAsyncStorage.settings)
        } else {
            return await getSettings()
        }
    }

    function possuiConfig(retGetSettings) {
        if (Platform.OS === 'web') {
            return retGetSettings.object?.url_porta
        } else {
            return retGetSettings.object?.length > 0 && retGetSettings.object[0].url_porta
        }
    }

    function verificarUsuario(retUsuario) {
        if (props?.obrigatorioVendedor && !retUsuario?.entven_id) {
            sendAlert('Configuração de Usuário', 'O usuário não possui um vendedor padrão configurado, configure-o pelo cadastro de usuário no ERP.')
            return false
        }

        return true
    }

    const adicionarUsuario = (retGetSettings, usuario) => {
        if (Platform.OS === 'web') {
            setItem(tablesAsyncStorage.user, usuario).then((retDb) => {
                hideProgressDialog()
                if (!retDb.success)
                    sendAlert(retDb.title, retDb.message)
                else {
                    setUser(usuario)
                    if (retGetSettings.object.id_filial) {
                        props?.navigation.reset({
                            index: 0,
                            routes: [{ name: props?.navigationHome }]
                        })
                    } else
                        props?.navigation.navigate('Settings2', { navigationHome: props?.navigationHome })
                }
            })
        } else {
            addUser(usuario).then((retDb) => {
                hideProgressDialog()
                if (!retDb.success)
                    sendAlert(retDb.title, retDb.message)
                else {
                    setUser(usuario)
                    if (retGetSettings.object[0].id_filial) {
                        props?.navigation.reset({
                            index: 0,
                            routes: [{ name: props?.navigationHome }]
                        })
                    } else
                        props?.navigation.navigate('Settings2', { navigationHome: props?.navigationHome })
                }
            })
        }
    }

    const entrar = () => {
        if (usrLogin && usrSenha) {
            buscarConfig().then((retGetSettings) => {
                if (!retGetSettings.success)
                    sendAlert(retGetSettings.title, retGetSettings.message)
                else {
                    if (possuiConfig(retGetSettings)) {
                        showProgressDialog('Efetuando Login')
                        PostRequisition(settings, props?.jsonLogin).then((retLogin) => {
                            if (!retLogin.success) {
                                hideProgressDialog()
                                sendAlert(retLogin.title, retLogin.message)
                            } else {
                                let usuario = retLogin.object.usuario
                                if (verificarUsuario(usuario)) {
                                    usuario['usr_login'] = usrLogin
                                    usuario['usr_senha'] = usrSenha
                                    usuario['usr_senha_email'] = criptografar(retLogin.object.usuario?.usr_senha_email, CHAVE_PASS_USER)
                                    adicionarUsuario(retGetSettings, usuario)
                                } else
                                    hideProgressDialog()
                            }
                        })
                    } else
                        sendAlert('Aplicativo não configurado', 'O aplicativo ainda não possui configuração, clique em "configurar" no rodapé desta tela')
                }
            })
        } else
            sendAlert('Credenciais', 'Informe o usuário e senha antes de entrar')
    }

    return (
        <View style={style.container}>
            <ImageBackground
                source={props?.backgroundImage}
                style={style.imageBackground}
                imageStyle={{
                    opacity: 0.5,
                    resizeMode: "cover",
                }}>
                <View style={style.viewContainer}>
                    <SafeAreaView style={style.safeAreaView}>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            {props?.showButtonInstallPwa &&
                                <IBSIconButton
                                    icon='install-desktop'
                                    iconDirectory='materialIcons'
                                    style={style.buttonInstallPwa}
                                    onPress={() => props?.actionButtonInstallPwa()}
                                />
                            }
                            <IBSIconButton
                                icon={theme === ThemeEnum.dark ? 'moon-outline' : 'sunny-outline'}
                                iconDirectory='ionicons'
                                style={style.buttonTheme}
                                transparent={true}
                                onPress={() => setActualTheme(theme === ThemeEnum.dark ? ThemeEnum.light : ThemeEnum.dark)}
                            />
                            <View style={style.viewTop}>
                                <Image source={props?.logo} style={style.imagemIBS} />
                            </View>
                            <View style={[style.viewCenter, screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}>
                                <IBSLogin
                                    onPressLogin={() => entrar()}
                                    valueLogin={usrLogin}
                                    valuePassword={usrSenhaTela}
                                    onChangeTextLogin={(value) => setLogin(value)}
                                    onChangeTextPassword={(value) => setSenha(value)}
                                />
                            </View>
                            <View style={style.viewBottom}>
                                <IBSSeparator style={style.separador} />
                                <IBSIconButton
                                    transparent={true}
                                    icon={'settings-outline'}
                                    iconDirectory={'ionicons'}
                                    size={16}
                                    style={style.settingsButton}
                                    styleText={style.textSettingsButton}
                                    children={'Configurar'}
                                    onPress={() => props?.navigation.navigate('Settings1')}
                                />
                            </View>
                        </View>
                    </SafeAreaView>
                </View>
            </ImageBackground>
        </View>
    )
}

/*

    PROPS

    navigation - Componente de navegação
    backgroundImage - Imagem de fundo da tela de aplicativo
    navigationHome - Nome da tela inicial do aplicativo
    jsonLogin - Json que irá efetuar o login no servidor
    obrigatorioVendedor - Se é obrigatório ter um vendedor padrão para o usuário

*/