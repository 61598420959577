import ApiService from "../../services/apiService";
import BuildReturn from "../../models/buildReturn";
import { descriptografar } from "../../utils/utils";
import { CHAVE_PASS_USER } from "@env"
import { Platform } from "react-native";

export default async function PostRequisition(settings, json, retry = 3) {
    let options = {}
    options.headers = {
        'Content-Type': 'application/json',
        'cnpjnginx': settings.cnpj
    }
    options.json = true
    options.resolveWithFullResponse = true
    if (settings.basicAuth) {
        if (Platform.OS === 'web') {
            options.auth = {}
            options.auth.username = settings.basicAuth.username
            options.auth.password = descriptografar(settings.basicAuth.password, CHAVE_PASS_USER)
        } else {
            options.auth = settings.basicAuth
        }
    }

    try {
        let result = {}

        while (retry > 0) {
            try {
                result = await ApiService(settings.baseUrl).post(settings.endpoint, JSON.stringify(json), options)
                break
            } catch (error) {
                if (error.code === 'ECONNABORTED' || error?.toString().indexOf('manualTimeout') > 0) {
                    await new Promise(resolve => setTimeout(resolve, 5000)); // Aguarda 5 segundos
                    retry-- //Em caso de Timeout (Axios/Manual), é feito mais duas tentativas de envio
                } else
                    retry = 0

                if (retry <= 0)
                    throw error;
            }
        }

        if (!result?.data)
            return BuildReturn(false, 'Exceção ao consumir API IBS', `API não retornou nenhum dado`)

        if (!result?.data.success) {
            if (result.data.object && result.data.object?.message)
                return BuildReturn(false, 'Mensagem API IBS', `${result.data.object?.message}`)

            return BuildReturn(false, 'Consumo API IBS', 'API não retornou nenhum registro')
        }

        // if (!result?.data.object)
        //     return BuildReturn(false, 'Exceção ao consumir API IBS', 'Comunicação com a API foi estabelecida porém, não retornou nenhum registro')

        return BuildReturn(true, '', '', result.data.object)
    } catch (error) {
        if (error?.response?.data?.object?.message)
            return BuildReturn(false, 'Erro Interno API IBS', `Log: ${error?.response?.data?.object?.message.replace(/\\n/g, '\n')}`)
        else if (error?.response?.data)
            return BuildReturn(false, 'Erro Interno API IBS', `Log: ${error?.response?.data}`)
        else if (error?.response?.data?.message)
            return BuildReturn(false, 'Exceção ao consumir API IBS', `Log: ${error?.response?.data?.message}`)
        else if (error?.toString().indexOf('timeout of') > 0 && error?.toString().indexOf('exceeded') > 0)
            return BuildReturn(false, 'Tempo Esgotado', `O tempo limite para a comunicação com o servidor esgotou, verifique sua conexão.`)
        else if (error?.toString().indexOf('manualTimeout') > 0) //Cancelamento que faço manual caso o timeout do axios falhe
            return BuildReturn(false, 'Operação Cancelada', `O servidor recebeu a solicitação, mas o aplicativo não obteve uma resposta no tempo previsto.`)
        else if (error?.toString().indexOf('Network Error') > 0) //Esse erro acontece geralmente por falta de internet, pode acontecer também ao tentar consumir um link http
            return BuildReturn(false, 'Erro na solicitação', `Não foi possível solicitar o servidor, verifique sua conexão com a internet.\n\nLog: ${error}`)
        else
            return BuildReturn(false, 'Exceção ao consumir API IBS', `Log: ${error}`)
    }
}