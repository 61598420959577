import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { View, TextInput, TouchableWithoutFeedback, TouchableOpacity, PanResponder } from "react-native";
import { styles } from "./styles";
import { ThemeContext } from "../../contexts/themeContext";
import IBSIcon from "../IBSIcon";
import { formatMask, isFieldRequired, isValidCNPJ, isValidCPF, retirarCaracteresEspeciais, validaEmail } from "../../utils/utils";
import IBSText from "../IBSText";
import IBSIconButton from "../IBSIconButton";
import CurrencyInput from 'react-native-currency-input';
import { ParametrosContext } from "../../contexts/parametrosContext";

export default function IBSTextInput(props) {

    const { IBSTheme, CustomTheme, font, fontBold } = useContext(ThemeContext)
    const { getNrCasasDecimais } = useContext(ParametrosContext)

    const style = styles(IBSTheme, CustomTheme, font)

    const [isFocused, setIsFocused] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isValid, setIsValid] = useState(true)

    const requiredField = props?.name && props?.requiredFields?.length > 0 && props?.requiredFields?.find(field => field.aplper_campo === props.name)
    const isRequired = props?.required || (requiredField && (requiredField.aplper_obrigatoriedade === 1 ||
        (requiredField.aplper_obrigatoriedade === 2 && isFieldRequired(requiredField.aplper_condicao, props?.objConditionRequired))))

    const hasIconRight = props?.secureTextEntry || props?.dropDown || props?.iconRight || props?.clearButton
    const iconColor = CustomTheme?.textInput?.iconColor ? CustomTheme?.textInput?.iconColor : IBSTheme?.textInput?.iconColor
    const showLabelOrRequired = (props?.label && !!props?.value) || (isRequired && props?.editable !== false)
    const inputIsTouchable = props?.touchableInput || props?.dropDown

    const isNumeric = props?.numeric
    const isText = props?.onlyText
    const isTextInput = !isNumeric && !isText

    const handleFocus = () => {
        setIsFocused(true);
        props?.onFocus?.()
    }

    const handleBlur = () => {
        setIsFocused(false);
        props?.onBlur?.()
    }

    const handleValue = useCallback((text) => {
        if (props?.mask)
            return formatMask(text, props?.mask)
        else
            return text
    }, [props.value, props.mask])

    const handleOnChange = useCallback((value) => {
        value = value.replace(/[^\x00-\x7F\xC0-\xFF]/g, ""); // Remove caracteres não aceitos por um banco LATIN1
        if (props?.mask || props?.removeSpecialChars || props?.maskOnChangeText) {
            value = retirarCaracteresEspeciais(value)
            if (props?.maskOnChangeText)
                value = formatMask(value, props?.maskOnChangeText)
        }

        props.onChangeText(value)
    }, [props.onChangeText])

    useEffect(() => {
        if (props?.validateEmail === true) {
            setIsValid(validaEmail(props?.value))
        }
    }, [props?.value, props?.validateEmail === true])

    useEffect(() => {
        if (props?.validateCpfCnpj === true) {
            const value = props?.value?.replace(/[^\d]+/g, '')
            if (value?.length === 11) {
                setIsValid(isValidCPF(value))
            } else if (value?.length === 14) {
                setIsValid(isValidCNPJ(value))
            } else {
                setIsValid(false)
            }
        }
    }, [props?.value, props?.validateCpfCnpj === true])

    return (
        <TouchableWithoutFeedback onPress={(inputIsTouchable && props?.editable !== false) ? props?.onPressIn : undefined}>
            <View
                pointerEvents={props?.editable === false ? 'none' : 'auto'} //Android pra pegar eventos de deslize do componente pai, precisa estar none
                style={[
                    style.viewContainer,
                    props?.useHeightDefault === false ? undefined : { height: 50 },
                    isFocused ? style.inputFocused : style.inputUnfocused,
                    props.styleContainer ? props.styleContainer : undefined,
                ]
                }>
                {props?.icon &&
                    <IBSIcon
                        icon={props?.icon}
                        iconDirectory={props?.iconDirectory}
                        size={22}
                        style={[style.icon]}
                        color={iconColor}
                    />}
                {(showLabelOrRequired) &&
                    <View style={[
                        style.labelContainer,
                        hasIconRight ? { paddingRight: 36 } : undefined,
                        props?.icon ? { paddingLeft: 45 } : { paddingLeft: 15 },
                        props?.styleLabel ? props?.styleLabel : undefined
                    ]}>
                        {props?.label && !!props?.value &&
                            <IBSText
                                style={style.fontLabel}
                                children={props?.label}
                                fontWeight={props?.labelBold ? 'bold' : undefined}
                            />
                        }
                        {isRequired && props?.editable !== false &&
                            <IBSText
                                style={[
                                    style.fontLabelRequired,
                                    props?.label && !!props?.value ? { paddingLeft: 4 } : undefined
                                ]}
                                children={'(Obrigatório)'}
                                fontWeight={props?.labelBold ? 'bold' : undefined}
                            />
                        }
                    </View>
                }
                {isNumeric &&
                    <CurrencyInput
                        ref={props?.innerRef ? props?.innerRef : undefined}
                        precision={getNrCasasDecimais()}
                        {...props}
                        onChangeText={undefined} //Não pode ter onChangeText, pois o componente CurrencyInput não funciona bem com ele
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        returnKeyType='done'
                        value={props?.value}
                        onChangeValue={props.onChangeValue}
                        editable={inputIsTouchable ? false : props?.editable || props?.editable === false ? props?.editable : true}
                        style={[
                            style.inputContainer,
                            props?.bold && !!props?.value ? { fontFamily: fontBold } : { fontFamily: font },
                            props?.style ? props?.style : undefined,
                            hasIconRight ? { paddingRight: 36 } : { paddingRight: 10 },
                            props?.icon ? { paddingLeft: 45 } : { paddingLeft: 15 },
                            showLabelOrRequired ? { paddingTop: 12 } : undefined,
                            isValid ? null : style.colorRed
                        ]}
                        placeholderTextColor={CustomTheme.textInput?.placeholderTextColor ? CustomTheme.textInput?.placeholderTextColor : IBSTheme.textInput?.placeholderTextColor}
                    />}
                {isTextInput &&
                    <TextInput
                        ref={props?.innerRef ? props?.innerRef : undefined}
                        {...props}
                        secureTextEntry={props?.secureTextEntry ? showPassword ? false : true : false}
                        returnKeyType='done'
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        pointerEvents={inputIsTouchable ? "none" : "auto"} //iOs precisa porque o textInput tbm tem um onPressIn que substitui o do Touchable
                        value={props?.mask ? handleValue(props?.value) : props?.value}
                        onChangeText={handleOnChange}
                        editable={inputIsTouchable ? false : props?.editable || props?.editable === false ? props?.editable : true}
                        style={[
                            style.inputContainer,
                            props?.bold && !!props?.value ? { fontFamily: fontBold } : { fontFamily: font },
                            props?.style ? props?.style : undefined,
                            hasIconRight ? { paddingRight: 36 } : { paddingRight: 10 },
                            props?.icon ? { paddingLeft: 45 } : { paddingLeft: 15 },
                            showLabelOrRequired ? { paddingTop: 12 } : undefined,
                            isValid ? null : style.colorRed
                        ]}
                        placeholderTextColor={CustomTheme.textInput?.placeholderTextColor ? CustomTheme.textInput?.placeholderTextColor : IBSTheme.textInput?.placeholderTextColor}
                        keyboardType={props?.keyboardType ? props?.keyboardType : 'ascii-capable'}
                    />
                }
                {isText &&
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <IBSText
                            children={!!props?.value ? props?.mask ? handleValue(props?.value) : props?.value : props?.placeholder}
                            fontWeight={props?.bold && !!props?.value ? 'bold' : undefined}
                            numberOfLines={1}
                            style={[
                                style.inputContainerText,
                                props?.style ? props?.style : undefined,
                                hasIconRight ? { paddingRight: 36 } : { paddingRight: 10 },
                                props?.icon ? { paddingLeft: 45 } : { paddingLeft: 15 },
                                showLabelOrRequired ? { paddingTop: 12 } : undefined,
                                !!props?.value ? undefined : { color: CustomTheme.textInput?.placeholderTextColor ? CustomTheme.textInput?.placeholderTextColor : IBSTheme.textInput?.placeholderTextColor },
                            ]}
                        />
                    </View>
                }
                {props?.iconRight &&
                    <IBSIcon
                        icon={props?.iconRight}
                        iconDirectory={props?.iconRightDirectory ? props?.iconRightDirectory : 'materialcommunityicons'}
                        size={props?.iconRightSize ? props?.iconRightSize : undefined}
                        style={[style.iconDropDown]}
                        color={props?.iconRightColor ? props?.iconRightColor : iconColor}
                    />
                }
                {props?.dropDown && props?.editable !== false &&
                    <IBSIcon
                        icon={props?.iconDropDown ? props?.iconDropDown : 'menu-down'}
                        iconDirectory={props?.iconDropDownDirectory ? props?.iconDropDownDirectory : 'materialcommunityicons'}
                        size={props?.iconDropDownSize ? props?.iconDropDownSize : undefined}
                        style={[style.iconDropDown]}
                        color={iconColor}
                    />
                }
                {props?.secureTextEntry &&
                    <IBSIconButton
                        icon={showPassword ? 'eye-off' : 'eye'}
                        iconDirectory='ionicons'
                        iconColor={iconColor}
                        transparent={true}
                        style={[style.iconShowPassword]}
                        onPress={() => setShowPassword(!showPassword)} />
                }
                {props?.clearButton && props?.value &&
                    <IBSIconButton
                        icon={'close-circle'}
                        iconDirectory={'ionicons'}
                        style={[style.iconShowPassword]}
                        iconColor={iconColor}
                        transparent={true}
                        onPress={() => handleOnChange('')}
                    />
                }
            </View>
        </TouchableWithoutFeedback>
    )
}

/*
    PROPS

    style - Estilo do TextInput
        Estilos personalizados tem prioridade nos estilos já definidos
    styleLabel - Estilo do Label
        Estilos personalizados tem prioridade nos estilos já definidos
    styleContainer - Estilo da View principal onde encontra-se o componente
        Default: Vazio
    bold -
        Booleano se necessita que o conteúdo do input seja com font negrito. Obs: Só conteúdo, placeholder e label não
    useHeightDefault - O InputText possui um height default de 50, caso não deseja usar nenhum height como por exemplo, precisa que o campo
        preencha todo um espaço indiferente do height, informar useHeightDefault: false
    innerRef - Referência do textInput, serve para focar no campo por exemplo
        default: vazio
    label - título do input
        default: vazio
    placeholder - indicativo da tarefa do campo
        default: vazio
    secureTextEntry - true/false para campo de senha - Cria também um "olho" para mostrar ou não a senha      
        default : false
    transparent - 
        Caso setar nos estilos que o input é transparente, coloque essa propriedade como true para o fundo do label criar uma view para esconder a borda
    colorViewTransparent - 
        Se setar a propriedade acima como true, coloque a cor que vai ser essa View, igual a cor de fundo da view pai, para o estilo ser realmente "transparente"
    dropDown - 
        Setar true o componente cria uma flecha no estilo drop-down para indicar que esse campo é uma lista de seleção
    mask -
        Se necessitar de uma máscara no campo, informar por exemplo: ###.###.###-##
    maskOnChangeText - 
        Funciona igual a propriedade mask, porém, essa irá colocar a máscara junto com o valor na variável que está sendo 
        alimentada, enquanto a mask apenas mostra para o usuário sem alterar seu valor
    icon - 
        Passar nomed do ícone que deseja, o ícone fica no lado esquerdo do textInput
    iconDirectory -
        Se necessitar de algum diretório de icones diferente do MaterialCommunityIcons passar aqui
    touchableInput -
        Ao colocar essa propriedade como true, o componente habilita o uso de outra propriedade onPressIn do Touchable
    iconDropDown - 
        ícone para o dropDown, default será uma flecha apontando para baixo
    iconDropDownDirectory - 
        Se necessita de um diretório diferente para o ícone. (consultar disponíveis no IBSIcon)
    iconDropDownSize - 
        Tamanho do ícone de drop down
    validateEmail - 
        Variável que verifica se o que está sendo digitado é um e-mail válido, caso contrário, a cor do texto fica em vermelho
    autoCapitalize="characters" -
        Força teclado a ficar com letra maiúsculas, outras opções são: "words" e "sentences"
    iconRight - 
        ícone para o lado direito do input
    iconRightDirectory -
        Se necessita de um diretório diferente para o ícone. (consultar disponíveis no IBSIcon)
    iconRightSize - 
        Tamanho do ícone do lado direito
    iconRightColor - 
        Cor do ícone do lado direito
    onlyText -
        Essa propriedade muda o tipo de componente dentro do estilo, ao invés de um TextInput, será um IBSText

    *** PROPRIEDADES PARA LABEL DE OBRIGATORIEDADE ***

    name - 
        uma id para esse campo, tem que ser o mesmo que será utilizado no banco de dados, ou seja, usar o nome do campo no banco, por exemplo: "entida_cpf"
    requiredFields - 
        array contendo o resultado da consulta de campos obrigatórios do banco de dados
    objConditionRequired - 
        objeto da tela que irá conter os valores dos campos que serão utilizados para verificar se o campo é obrigatório ou não
    required - 
        Se necessita que o campo seja obrigatório indiferente de configuração no banco, informar true

    ** PROPRIEDADES PARA O CURRENCY INPUT **

    numeric - 
        Ao ativar essa propriedade, o componente passa a ser um CurrencyInput, que é um input de moeda, com máscara e formatação
    onChangeValue - 
        ATENÇÃO - ao usar o Currency Input, a propriedade passa a ser onChangeValue ao invés de onChangeText

    prefix="R$"
    delimiter="." - padrão
    separator="," - padrão
    precision={2} - padrão será o configurado no parâmetro 110 de casas decimais, se necessitar outro basta informar nessa propriedade
    minValue={0}
    showPositiveSign
    
*/