import { Alert, Platform } from "react-native"

export const sendAlert = (title, message) => {
    if (Platform.OS === 'web') {
        alert(`${title}\n${message}`)
    } else {
        Alert.alert(title, message, [{ text: "Fechar" }])
    }
}

export const sendQuestion = (title, message, actBtnConfirm = undefined, actBtnCancel = undefined, txtBtnConfirm = 'Sim', txtBtnCancel = 'Não') => {
    Alert.alert(title, message, [
        {
            text: txtBtnCancel,
            onPress: actBtnCancel,
            style: 'cancel'
        },
        {
            text: txtBtnConfirm,
            onPress: actBtnConfirm
        }
    ])
}