import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        container: {
            width: '100%'
        },
        textInputContainer: {
            marginBottom: 10,
        },
        ButtonEntrar: {
            backgroundColor: customTheme.button?.backgroundColor ? customTheme.button?.backgroundColor : theme.button?.backgroundColor,
            height: 50,
            marginTop: 7
        },
    })
}