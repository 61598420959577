import { StyleSheet } from "react-native";

export const styles = (theme, customTheme) => {
    return StyleSheet.create({
        blurContainer: {
            borderRadius: 10,
            paddingVertical: 7
        },
        text: {
            fontSize: 15
        },
        viewRow: {
            flexDirection: 'row',
            paddingHorizontal: 5,
        },
        separador: {
            marginTop: 5
        },
        viewText: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        viewIcon: {
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        viewBorder: {
            borderRadius: 10,
            overflow: 'hidden',
        },
    })
}