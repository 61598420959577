import { useContext, useEffect, useState } from "react";
import { View, Dimensions, Platform } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import IBSIconButton from "../../../components/IBSIconButton";
import IBSText from "../../../components/IBSText";
import IBSTextInput from "../../../components/IBSTextInput";
import { ProgressDialogContext } from "../../../contexts/progressDialogContext";
import { ThemeContext } from "../../../contexts/themeContext";
import { styles } from "./style";
import PostRequisition from "../../../requisitions/wsIBS/postRequisition"
import { IBSWsSettingsContext } from "../../../contexts/ibsWsSettingsContext";
import { updateSettingsFilial } from "../../../services/database/tables/settings";
import { sendAlert } from "../../../services/messagingCenter";
import { useRoute } from "@react-navigation/native";
import IBSHeader from "../../../components/IBSHeader";
import IBSPicker from "../../../components/IBSPicker";
import IBSPressCard from "../../../components/IBSPressCard";
import { IBSCompanyContext } from "../../../contexts/ibsCompanyContext";
import { useToast } from "../../../contexts/toastContext";
import { handlerJsonFilialObterModel } from "../../../models/requisitions/handlerRequisitions";
import { getItem, updateItems } from "../../../services/database/asyncStorageService";
import { tablesAsyncStorage } from "../../../models/enums";

export default function Settings2View({ navigation }) {

    const { IBSTheme, CustomTheme } = useContext(ThemeContext)
    const { settings, deviceId, nmAplicativo } = useContext(IBSWsSettingsContext)
    const { showProgressDialog, hideProgressDialog } = useContext(ProgressDialogContext)
    const { setIdFilial, setNmFilial, setNmFilialExibicao } = useContext(IBSCompanyContext)
    const { showToast } = useToast()

    const style = styles(IBSTheme, CustomTheme)

    const route = useRoute()

    const [objFilial, setObjFilial] = useState({})
    const [arrayObjFilial, setArrayObjFilial] = useState([])
    const [listFiliaisVisible, setListFiliaisVisible] = useState(false)
    const [screenWidth, setScreenWidth] = useState(Dimensions.get("window").width)

    useEffect(() => {
        const updateWidth = () => {
            setScreenWidth(Dimensions.get("window").width)
        }
        const eventUpdateWidth = Dimensions.addEventListener("change", updateWidth);
        return () => eventUpdateWidth.remove()
    }, [])

    useEffect(() => {
        buscarFiliais()
    }, [])

    const buscarFiliais = () => {
        showProgressDialog('Buscando Filiais')
        const json = handlerJsonFilialObterModel(nmAplicativo, deviceId)
        PostRequisition(settings, json).then((ret) => {
            hideProgressDialog()
            if (!ret.success)
                sendAlert(ret.title, ret.message)
            else
                setArrayObjFilial(ret.object.filiais)
        })
    }

    const salvar = async () => {
        if (objFilial && Object.keys(objFilial).length > 0) {
            setIdFilial(objFilial.filial_id)
            setNmFilial(objFilial.filial_nome)
            setNmFilialExibicao(objFilial.filial_nome_exibicao)
            
            if (Platform.OS === 'web') {
                getItem(tablesAsyncStorage.settings).then((ret) => {
                    if (!ret.success)
                        sendAlert(ret.title, ret.message)
                    else {
                        let settings = ret.object
                        settings.id_filial = objFilial.filial_id
                        settings.nm_filial = objFilial.filial_nome
                        settings.nm_filial_exibicao = objFilial.filial_nome_exibicao
                        updateItems([tablesAsyncStorage.settings], [settings]).then((ret) => {
                            if (!ret.success)
                                sendAlert(ret.title, ret.message)
                            else {
                                showToast('Filial atualizada com sucesso')
                                if (route.params?.navigationHome) {
                                    navigation.reset({
                                        index: 0,
                                        routes: [{ name: route.params?.navigationHome }]
                                    })
                                } else {
                                    navigation.goBack()
                                }
                            }
                        })
                    }
                })
            } else {
                updateSettingsFilial(objFilial).then((ret) => {
                    if (!ret.success)
                        sendAlert(ret.title, ret.message)
                    else {
                        showToast('Filial atualizada com sucesso')
                        if (route.params?.navigationHome) {
                            navigation.reset({
                                index: 0,
                                routes: [{ name: route.params?.navigationHome }]
                            })
                        } else {
                            navigation.goBack()
                        }
                    }
                })
            }
        } else sendAlert('Selecione a Filial', 'Pressione na lista e selecione a filial desejada para efetuar o login')
    }

    return <SafeAreaView style={style.safeAreaView}>
        {listFiliaisVisible && arrayObjFilial && arrayObjFilial.length > 0 &&
            <IBSPicker
                data={arrayObjFilial}
                onPress={(item) => {
                    setObjFilial(item)
                    setListFiliaisVisible(false)
                }}
                renderItem={(item) => `${item.filial_id} - ${item.filial_nome_exibicao}`}
                onCancel={() => setListFiliaisVisible(false)}
                labelKey={'filial_id'}
            />
        }
        <IBSHeader headerText={'Configuração'} navigation={navigation} backButton={true} />
        <View style={style.container}>
            <IBSText
                style={style.textInformativo}
                children='Selecione a Filial'
            />
            <View style={[style.viewRowTextInput, screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}>
                <IBSTextInput
                    label='Filial'
                    styleContainer={style.textInputContainer}
                    style={style.textInput}
                    dropDown={true}
                    value={objFilial.filial_nome_exibicao}
                    placeholder={'Selecione a Filial'}
                    onPressIn={() => setListFiliaisVisible(true)}
                />
                <IBSIconButton
                    icon='search'
                    iconDirectory='materialIcons'
                    style={style.buttonSearch}
                    onPress={buscarFiliais}
                />
            </View>
            {objFilial && objFilial.filial_id &&
                <IBSPressCard style={[screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}>
                    <View style={style.viewCard}>
                        <View style={style.viewRow}>
                            <IBSText style={[style.textInputTitleCard, style.textInputCard]} fontWeight={'bold'}>{'ID Filial'}</IBSText>
                            <IBSText style={style.textInputCard}>{objFilial.filial_id}</IBSText>
                        </View>
                        <IBSText style={[style.textInputTitleCard, style.textInputCard]} fontWeight={'bold'}>{'Razão Social'}</IBSText>
                        <IBSText style={style.textInputCard}>{objFilial.filial_nome}</IBSText>
                        <IBSText style={[style.textInputTitleCard, style.textInputCard]} fontWeight={'bold'}>{'Nome Exibição'}</IBSText>
                        <IBSText style={style.textInputCard}>{objFilial.filial_nome_exibicao}</IBSText>
                    </View>
                </IBSPressCard>
            }
        </View>
        <View style={style.viewButton}>
            <IBSIconButton
                icon='save'
                size={20}
                iconDirectory='materialIcons'
                iconAfter={true}
                children='Salvar'
                style={[style.button, screenWidth <= 600 ? { width: '80%' } : screenWidth <= 1250 ? { width: '50%' } : { width: '30%' }]}
                onPress={() => salvar()}
            />
        </View>
    </SafeAreaView>
}

/*

    PROPS

    navigation - Componente de navegação
    navigationHome - 

*/