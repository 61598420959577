import React from "react";
import debounce from "lodash.debounce";

const PreventDoubleOnPressService = (WrappedComponent, msDelay = 0) => {

    class funcPreventDoubleOnPress extends React.PureComponent {

        debounceOnPress = () => {
            this.props.onPress && this.props.onPress()
        }

        onPress = debounce(this.debounceOnPress, msDelay, { leading: true, trailing: false })

        render() {
            return <WrappedComponent {...this.props} onPress={this.onPress} />
        }
    }

    return funcPreventDoubleOnPress
}

export default PreventDoubleOnPressService